.row {
  margin: 0;
}

.row--with_margin {
  margin-left: -15px;
  margin-right: -15px;
}

.row--big {
  margin: 0 calc(40px - 1.3333vw);
  .column {
    padding: 1.3333vw;
  }
}

// Special case for the sub-page layout or if the grid only contains
// one single box element. The grid column(s) will be left-aligned.
@media (min-width: 769px) {
  .Content--SubPage .row--big,
  .row--big.isSingleItem {
    margin: 0 -1.333vw;
  }

  .ContentWrap--Home .isSingleItem {
    margin: 0 calc(40px - 1.3333vw);
  }
}
@media (max-width: 768px) {
  .row--big {
    margin: 0;
    .column {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .Content--SubPage .row--big,
  .row--big.isSingleItem {
    width: 100%;
    margin: 0;

    .column {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
