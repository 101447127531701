h2,
.h2 {
  @include hyphenate();

  font-size: 6vw;
  line-height: 1.17;
  margin-bottom: 33px;
}

.ContentElement:not(:first-child) {
  header {
    > h2,
    .h2 {
      margin-top: 120px;
    }
  }
  header {
    > h3,
    .h3 {
      margin-top: 100px;
    }
  }
  .Text {
    > .Text__Text {
      > h3 {
        margin-top: 100px;
      }
    }
  }
}

h2,
.h2 {
  font-weight: 300;
}

h3,
.h3 {
  @include hyphenate();

  font-size: 40px;
  font-weight: 300;
  line-height: 1.27;
  margin-bottom: 33px;
  &.small {
    font-size: 26px;
  }
}

h4,
.h4 {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.27;
  &.small {
    font-size: 26px;
  }
}

@media (max-width: 768px) {
  h2,
  .h2 {
    font-size: 40px;
  }
  h3,
  .h3 {
    font-size: 26px;
  }

  .MobileContentAccordionHeaderWrapper {
    &.ContentElement:not(:first-child) {
      header {
        > h2,
        .h2 {
          margin-top: 0px;
        }
      }
      header {
        > h3,
        .h3 {
          margin-top: 0px;
        }
      }
      .Text {
        > .Text__Text {
          > h3 {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 80px;
  }
}

.hyphenate {
  @include hyphenate();
}
.removeHyphenate {
  @include removeHyphenate();
}
@media (max-width: 400px) {
  h2.removeHyphenate {
    font-size: 32px;
  }
}

.RteContent {
  @include hyphenate;

  ul {
    list-style-type: none;

    li {
      position: relative;
    }

    li:before {
      content: '';
      width: 16px;
      border-bottom: 1px solid;
      position: absolute;
      top: 1em;
      right: 100%;
      margin: -5px 12px 0 0;
    }

    li:not(:last-child) {
      padding-bottom: 0.333em;
    }
  }
  p,
  li {
    a {
      display: inline-block;
      font-weight: 800;
      &[data-link-type='page'] {
        @include hiddenIconLink();
      }
    }
  }
}

a {
  color: $color-granite-weboptimized;
  &:hover {
    color: $color-black;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
}
