@mixin iconLink {
  display: inline-block;

  &:before {
    @include htwIcon();

    display: inline-block;
    margin-right: 5px;
    vertical-align: bottom;
  }
}

@mixin hiddenIconLink {
  &:before {
    display: none !important;
  }
}
