.Text__Text {
  font-size: 20px;
  line-height: 1.476;
  font-weight: 300;
}

.Text__Title {
  font-size: 26px;
  font-weight: 300;
  line-height: 1.27;
}

.ContentElement--Text {
  margin-bottom: 3vw;
}

.Images .row {
  margin-left: -15px;
  margin-right: -15px;
}
.Images img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.image-caption {
  margin-top: 10px;
  font-weight: 300;
}

.Text__Image {
  .Images {
    video,
    iframe {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      @include media-breakpoint-down(xs) {
        aspect-ratio: 4/3;
      }
    }
  }
}
.line-height-default {
  line-height: var(--bs-body-line-height);
}
.font-inline {
  display: inline;
}
.font-size-h1 {
  font-size: 32px;
  @media (min-width: 769px) and (max-width: 1100px) {
    font-size: 60px;
  }
  @media screen and (min-width: 1100px) {
    font-size: 80px;
  }
}
.font-weight-h1 {
  font-weight: var(--bs-body-font-weight);
}
