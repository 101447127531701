.FactBox {
  background: $color-ocher-light;
  color: $color-black;
  padding: 30px;

  font-size: 20px;
  font-weight: 300;
  line-height: 1.81;
  a[data-link-type]:before {
    line-height: 1.81 !important;
  }
  a {
    font-weight: 800;
    color: $color-black;
  }
}

.FactBox__Text > :last-child {
  margin-bottom: 0;
}

.FactBox__Title {
  font-size: 20px;
  line-height: 1.45;
  margin-bottom: 18px;
}

.FactBox__List {
  list-style-type: none;
  padding-left: 40px;
}

.FactBox__Text {
  line-height: 1.476;

  h3 {
    font-size: 26px;
    font-weight: 300;
    line-height: 1.32;
    margin-bottom: 15px;
  }
}

.FactBox__ListItem {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.476;

  margin-bottom: 12px;

  &:before {
    content: '—';
    width: 35px;
    display: inline-block;
    margin-left: -40px;
  }
}

.FactBox__Image {
  margin-top: 30px;
}
