.Plugin--EventsList,
.Plugin--NewsList {
  position: relative;
  margin-top: 70px;

  .ShowMore {
    bottom: calc(100% + 20px);
  }
}

.Header--AgendaScroller {
  font-size: 32px;
  line-height: 3.16;

  margin-left: 4%;
  margin-bottom: 30px;
  text-decoration: underline;

  font-weight: 400;

  a {
    color: black;
  }
}

.Slider--Events,
.Slider--News {
  .Slider__Slide {
    border: #dadadc 1px solid;

    a {
      height: 100%;
      display: block;
      text-decoration: none;
      color: black;

      &:hover {
        color: black;
        text-decoration: none;
        opacity: 0.6;
      }

      .AgendaItem {
        height: 100%;

        .AgendaItem__wrap {
          height: 100%;
        }
      }
    }
  }
  .slick-list {
    .slick-track {
      width: 100% !important;
      display: inline-flex;
      .column {
        &:first-child {
          margin-left: -1.3333vw;
        }
      }
      .slick-slide {
        height: auto;
        min-height: 1px;
        > div {
          -webkit-flex: 1;
          flex: 1;
          height: 100%;
        }
        .Event {
          height: 100%;
        }
        .Slider__Slide {
          height: 100%;
        }
      }
    }
  }
  .slick-next {
    // padding-right: 0;
  }
}

.Content--SubPage {
  .Slider--News,
  .Slider--Events {
    padding-left: 0;
  }
}

.AgendaItem--Highlighted {
  color: $color-ocher;
}

.AgendaItem--teaser,
.AgendaItem--search {
  font-size: 16px;
  font-weight: 200;

  padding: 30px;
  position: relative;

  .AgendaItem__Day {
    font-size: 120px;
    line-height: 1;
  }

  .AgendaItem__Date {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 18px;
  }

  .AgendaItem__Month {
    display: flex;
    margin-top: 16px;
  }
  .AgendaItem__Location {
    margin-bottom: 10px;
    i {
      padding: 0 5px 0 0;
      display: inline-block;
    }
    .icon-location {
      transform: translateY(2px);
    }
    .flaticon-internet {
      transform: translateY(1px);
    }
  }
  .AgendaItem__Type {
    @include agendaItemType();
  }

  .AgendaItem__Studyname {
    font-size: 38px;
    line-height: 1.29;
  }

  .AgendaItem__Description {
    padding-right: 60px;
  }

  .AgendaItem__Title {
    font-size: 32px;
    line-height: 1.29;
    padding-bottom: 70px;
  }

  .AgendaItem__MoreLink {
    position: absolute;
    left: 30px;
    bottom: 30px;
    color: $color-granite-weboptimized;
    font-size: 20px;
    font-weight: 500;
  }

  .NewsItem__ReadMore p {
    left: 0;
    bottom: 18px;
    position: absolute;
    padding: 30px;
    margin-bottom: 0;
    font-size: 20px;
    color: $color-granite-weboptimized;
    font-weight: 500;
  }

  @media (max-width: $sm-screen-width) {
    .AgendaItem__Title {
      font-size: 18px;
      line-height: 24.3px;
    }
    .AgendaItem__MoreLink {
      font-size: 16px;
      line-height: 21.6px;
    }
    .AgendaItem__Title {
      padding-bottom: 32px;
    }
    .AgendaItem__Location {
      margin-bottom: 16px;
    }
    .AgendaItem--teaser {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

.AgendaItem--search {
  font-weight: 200;
  padding: $agenda-item-top-padding $agenda-item-left-right-padding 18px
    ($agenda-item-bottom-padding - 19px);

  .AgendaItem__Day {
    font-weight: 200;
  }
  .AgendaItem__Title {
    font-size: 28px;
    line-height: 1.29;
  }
}

.Agenda__MoreLink {
  font-size: 20px;
  line-height: 5.05;
  color: black;

  &:after {
    content: '>';
    padding-left: 16px;
  }
}

.AgendaItem__ReadMore {
  position: relative;
  bottom: 71px;
  margin-top: 30px;
  font-size: 20px;
  color: $color-granite-weboptimized;
  font-weight: 500;
  margin-bottom: 0;
}
