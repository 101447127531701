@mixin mobileSpacing {
  padding: 0 $header-image-left-right-spacing-mobile;

  @include media-breakpoint-up(md) {
    padding: 0 40px;
  }
}

@mixin fullWidthBg {
  max-width: none;
  padding: {
    left: 0;
    right: 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 200vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(-50%);
    z-index: -1;
  }
}

.Landingpage {
  &__Title,
  &__SubTitle {
    @include media-breakpoint-up(md) {
      margin: 0;
    }

    .Landingpage__TitleWrap--Title {
      @include media-breakpoint-down(md) {
        padding: 2px 8px !important;
      }
    }
  }

  &__Title {
    font-size: 32px;
    margin-bottom: 30px;
    h1 {
      display: inline;
      font-size: 32px;
      font-weight: var(--bs-body-font-weight);
      line-height: var(--bs-body-line-height);
    }

    @include media-breakpoint-up(md) {
      font-size: 60px;
      h1 {
        font-size: 60px;
      }
    }

    @include media-breakpoint-up(big) {
      font-size: 60px;
      h1 {
        font-size: 60px;
      }
    }
  }

  &__SubTitle {
    font-size: 22px;
    border-top: 1px solid #cfcace;
    border-bottom: 1px solid #cfcace;
    font-weight: 300;
    padding: 10px 0;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      font-size: 30px;
      margin-bottom: 60px;
    }
  }

  &__Sticker {
    padding-bottom: 85px;

    .Sticker__Button {
      background-color: $color-granite-weboptimized;
    }
  }
}

@include textWithBackground(
  'Landingpage__TitleWrap--Title',
  $color-ocher,
  white
);

.HeaderImage__WrapperLayout-Landingpage {
  margin-bottom: 30px;

  @include media-breakpoint-down(sm) {
    height: calc(100vw - 44px);
  }

  &.hasMedia {
    aspect-ratio: 1;
  }

  .HeaderImage {
    height: 100%;
    object-fit: cover;
  }

  video.HeaderImage {
    max-width: 100%;
    aspect-ratio: 1;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
      width: auto;
    }
  }
}

.ContentWrap--Landingpage {
  @include mobileSpacing;

  padding-bottom: 0;

  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }

  @media (max-width: 992px) {
    .SubNavigation {
      display: none;
    }
  }

  .MobileContentAccordionHeader {
    background: $color-ocher-light;
    border-bottom: none;
  }

  .Content {
    .ContentElement {
      header h3 {
        font-weight: 300;
        line-height: 1.27;
        padding: 20px 22px 0 22px;
        margin: 50px 0 0 0;

        @include media-breakpoint-up(lg) {
          font-size: 30px;
          padding: 20px 0 0 0;
          margin-top: 58px;
        }
      }

      &:not(.MobileContentAccordionHeaderWrapper) {
        margin: 0;
        padding: 0;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      @include media-breakpoint-up(lg) {
        &:not(.ContentElement--Accordion):last-child {
          margin-top: 42px;
        }

        &.frame-type-template_accordion:last-child {
          margin-top: 15px;
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    .MobileContentAccordionHeaderWrapper {
      padding: 0 22px;

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }

    .ContentElement--InformationLinks {
      max-width: 100%;

      @include media-breakpoint-up(md) {
        max-width: max-content;
      }
    }
  }

  .frame-layout-99 {
    @include fullWidthBg;

    padding: 10px 0 20px 0;
    margin: 23px 0;
    background: #f0efec;
    max-width: 718px;

    &:before {
      background: #f0efec;
    }

    form {
      .mdc-form-group {
        label {
          font-size: 1.25rem;
        }

        .choices {
          .choices__inner {
            padding: 10px 22px;
          }
          .choices__list {
            .choices__item {
              @include media-breakpoint-down(sm) {
                word-break: keep-all;
              }
            }
          }
        }
      }

      .actions {
        .form-navigation {
          .btn-toolbar {
            .btn-group {
              .next {
                @include media-breakpoint-down(sm) {
                  width: 100%;
                }

                .form-navigation--button {
                  background-color: $color-granat;
                  color: $white;
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        padding: 0 22px;
      }

      #htwCrmForm-crm_element_7 {
        display: none;
      }
    }

    .form-success {
      .header {
        font-size: 1.375rem;
        margin-top: 36px;

        @include media-breakpoint-up(md) {
          font-size: 1.875rem;
        }
      }
    }
  }
}

.FooterWrap--Landingpage {
  margin-top: -25px;
  color: $white;

  li a {
    color: inherit;
  }

  .Footer__TriangleWrapper {
    top: calc(-5vw + 1px);
  }

  .QualityLabels {
    margin: 0 22px;

    border-bottom: none;
    padding: 0;

    .QualityLogo {
      display: none;
      &:first-child {
        display: block;
        img {
          filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(298deg)
            brightness(105%) contrast(103%);
        }
      }
    }
  }

  .FooterBorder {
    border-top: 1px solid $white;
  }

  .FooterEnd {
    margin: 0 22px;

    width: auto;
    padding: 0;

    .FooterContent__SocialMediaList {
      @include media-breakpoint-down(lg) {
        display: flex;
        max-width: 400px;
      }

      .FooterContent__SocialMediaItem {
        @include media-breakpoint-down(lg) {
          padding-right: 10px;
          margin-right: auto;
        }
      }
    }
  }

  .FooterNavigation {
    margin-right: 0;

    li {
      text-align: left;
    }
  }

  .FooterContent__SocialMediaItem a i {
    color: $white;
  }
}
