.slick-prev,
.slick-next {
  position: absolute;
  color: white;
  z-index: 10;
  margin-top: -88px;
  top: 50%;

  cursor: pointer;

  height: 176px;
  width: 79px;

  padding-top: 44px;

  svg {
    width: 46px;
    height: 88px;

    .st0 {
      fill: $color-granite-weboptimized;
    }
    .st1 {
      fill: $color-granite-light-weboptimized;
    }
  }

  &:hover svg {
    .st0 {
      fill: black;
    }
    .st1 {
      fill: white;
    }
  }
}

.slick-prev {
  left: 0;
  padding-left: 10px;
  svg {
    transform: scaleX(-1);
  }
}

.slick-next {
  right: 0;
  padding-right: 10px;
  padding-left: 23px;
}

.SliderWrapper {
  width: 100%;
  overflow: hidden;
}

.Slider {
  width: 100%;
}

@mixin selectOverflowSliders() {
  .Slider--Person,
  .Slider--Testimonial,
  .Slider--Events,
  .Slider--News,
  .Slider--MediaItem,
  .Slider--FiveReasons,
  .Slider--Logo {
    @content;
  }
}

// By default the sliders will show their next slides
// use this to disable the "overflow"
@mixin sliderHideRightOverflow() {
  @include selectOverflowSliders() {
    &::after {
      content: '';
    }
  }
}

@include selectOverflowSliders() {
  // Setup a scroll overflow hidden on the left side
  // Better would be clip-path which isn't supported yet
  &::before,
  &::after {
    display: block;
    position: absolute;
    top: 0;
    color: rgba(255, 255, 255, 0);
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 1;
  }

  &::before {
    content: '';
    // The -100% rendering sometimes cuts the first 1px from the slider, so we add a -1px so that this doesnt happen
    left: calc(-100% + -1px);
  }

  &::after {
    // The -100% rendering sometimes cuts the first 1px from the slider, so we add a -1px so that this doesnt happen
    right: calc(-100% + -1px);
  }

  // Disable (slick) default linebreak above slider
  .slick-track::before {
    content: none;
  }

  .slick-list.row--big {
    margin-right: 0;

    .slick-track {
      padding-right: 0;
    }
  }

  // Use the normal bootstrap column paddings for the slider items
  // (The website currently removes the paddings in mobile for all bootstrap rows/columns...)
  @media screen and (max-width: 480px) {
    .slick-list {
      margin-left: -14px;
      margin-right: -14px;
      .slick-track .slick-slide {
        padding-left: 14px;
        padding-right: 14px;
      }
    }
  }
  @media screen and (max-width: 769px) {
    .slick-list {
      width: auto;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 769px) {
    .slick-list {
      margin-left: -15px;
      margin-right: -15px;
      .slick-track .slick-slide {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.Content--Home .Slider--Testimonial,
.Content--Home .Slider--MediaItem,
.Content--Home .Slider--FiveReasons,
.Content--Home .Slider--Logo {
  margin: 0;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .Content--Home .Slider--Testimonial,
  .Content--Home .Slider--MediaItem,
  .Content--Home .Slider--FiveReasons,
  .Content--Home .Slider--Logo {
    margin-left: 12px;
  }
}

.slick-track {
  margin: 0;
}

.slick-disabled {
  display: none !important;
}

.Slider {
  width: 100%;
  height: 50px;
  opacity: 0;
}

.slick-initialized {
  opacity: 1;
  height: auto;
}

.Slider {
  .slick-prev,
  .slick-next {
    bottom: 0;
    margin: 0;
    top: auto;
    width: auto;
    align-items: center;
    justify-content: center;
    display: flex;

    height: 1.5rem;
    padding: 0;
    @media screen and (min-width: $sm-screen-width) {
      padding: 0.5rem 2rem;
      height: 3.5rem;
      margin-bottom: -0.25rem;
    }

    svg {
      height: auto;

      width: 0.75rem;
      @media screen and (min-width: $sm-screen-width) {
        width: 25px;
      }

      path.st1 {
        display: none;
      }
    }
  }
  .slick-prev {
    padding-right: 1rem;
  }
  .slick-next {
    padding-left: 1rem;
  }
}

.Slider__PageCounter {
  font-weight: 200;
  font-size: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding-top: 1rem;
}

.Slider__CurrentPage {
  font-size: 2.1rem;
}

.Slider__PageCounterLabel {
  padding: 0 0.5rem;
}

.Slider__Error {
  background-color: $color-ocher-light;
  padding: 30px;
  font-size: 2.1rem;
  @media screen and (min-width: $sm-screen-width) {
    padding-left: 40px;
    padding-right: 60px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.Slider--Accordion {
  .slick-track {
    width: 100% !important;
    display: inline-flex;
  }
}
