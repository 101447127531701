.Slider--MediaItem,
.MediaItem--Slider,
.Slider-FiveReasons,
.FiveResons--Slider {
  position: relative;

  .slick-track {
    // Overrides an inline width
    width: 100% !important;
    display: inline-flex;
  }

  .slick-slide {
    margin-right: 12px;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .MediaItem--Slider,
  .FiveResons--Slider,
  .Accordion .slick-slide {
    width: 596px;
    max-width: 100%;
    margin-right: 12px;

    .MediaItem__Image,
    .FiveReasonsItem__Image,
    .Slider__Slide {
      width: 596px;
      max-width: 100%;
      height: auto;

      video,
      iframe {
        width: 100%;
        aspect-ratio: 16/9;
        margin-bottom: -7px;
      }
    }
    .MediaItem__Link:hover,
    .FiveReasonsItem__Link:hover {
      opacity: 0.8;
    }
  }

  .slick-slide:last-child {
    .MediaItem--Slider,
    .FiveReasons--Slider {
      margin-right: 0;
    }
  }

  .Accordion {
    .slick-slide {
      padding: 0;

      .Slider__Slide {
        width: 596px !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .MediaItem--Slider,
  .FiveResons--Slider {
    .MediaItem__Image,
    .FiveReasonsItem__Image {
      video,
      iframe {
        width: calc(100vw - 56px);
        aspect-ratio: 16/9;
        margin-bottom: -7px;
      }
    }
  }
}
