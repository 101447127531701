body {
  &[class^='error-'] {
    .HeaderWithoutImage {
      display: none;
    }

    .Content--SubPage {
      background-repeat: no-repeat;
      background-position: 0 0;
    }
  }

  &.error-500 .Content--SubPage {
    background-size: 400px 400px;
    background-image: url(../../Public/Frontend/Images/htw-svgs/500.svg);
  }
  &.error-404 .Content--SubPage {
    background-size: 333px 333px;
    background-image: url(../../Public/Frontend/Images/htw-svgs/404.svg);
  }
}

@media (min-width: 769px) {
  body[class^='error-'] .Content--SubPage > .Content--Main {
    margin-left: 333px;
  }
}

@media (max-width: 769px) {
  body[class^='error-'] .Content--SubPage > .Content--Main {
    background: rgba(255, 255, 255, 0.5);
  }
}
