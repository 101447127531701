.Form__InputWrap {
  position: relative;
  width: 100%;

  &.Form__InputWrap--Search {
    & > i {
      width: 24px;
      height: 24px;
      font-size: 24px;

      position: absolute;
      top: 50%;
      margin-top: -14px;

      z-index: 10;
    }
  }
}

.Form__Input {
  height: 52px;
  width: 100%;

  border: none;
  border-bottom: solid 1px $color-gray-dark;

  appearance: none;
  outline: none;

  font-size: 20px;

  padding-left: 36px;

  z-index: 20;

  background: transparent;
}

.Form__InputSubmit {
  position: absolute;

  top: 50%;
  right: 0;

  margin-top: -14px;
  font-size: 24px;
  width: auto;
  height: 24px;

  background: none;
  border: none;
  padding: 0;

  color: $color-ocher;
}

.Form__Input--Search {
  padding-left: 0;
}

.Form__InputSubmit--Search {
  color: $color-ocher;
  cursor: pointer;
  &:hover,
  &:focus {
    color: $color-black;
  }
}
