@mixin miniCallToAction() {
  display: inline-block;
  background-color: $color-granite-weboptimized;
  border: none;
  color: $white;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 1.476;
  padding: 12px 20px;
  box-shadow: 0 3px 4px rgba($color-black, 0.3);
  max-width: max-content;

  &.LinkColorGranat {
    background-color: $color-granat;
  }

  &:before {
    margin-right: 5px;
  }
  &:after {
    margin-left: 5px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    min-width: 100%;
    padding: 13px 20px;
  }

  &[data-link-type] {
    &:before {
      line-height: 2.8 !important;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  a:hover {
    text-decoration: none;
    cursor: pointer;
  }

  i {
    position: absolute;
    left: 0;
    top: 50%;
    height: 28px;
    margin-top: -13px;

    display: inline-block;
  }
}
