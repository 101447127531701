@mixin HeaderNavigationOverlayBase {
  background-color: red;

  &.Overlay {
    height: calc(100vh - #{$header-height});

    @media (min-width: $lg-screen-width + 1) {
      position: absolute;
      top: $header-height;
    }
  }
}
