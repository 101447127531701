.Extension--Htwaddress.Plugin--NewsDetails {
  display: flex;
  font-size: 20px;

  h2 {
    // Style taken from h3
    font-size: 40px;
    font-weight: 300;
    line-height: 1.27;
    margin-bottom: 30px;
  }

  .Person__Image img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  figcaption {
    margin-top: 12px;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 40px;
  }

  .NewsDetail__HeaderImage {
    height: fit-content;
    overflow: hidden;
  }

  .NewsDetail__Linkbox {
    width: 100%;
    padding: 24px;
    border: 1px #cacaca solid;
    &_Linktitle {
      font-family: $font-family-sans-serif;
      font-size: 24px;
      font-weight: 300;
    }
    &_Link {
      display: block;
    }
  }

  .NewsDetail__SubjectLinks {
    margin-top: 60px;
  }

  .NewsDetail__FileLinks {
    margin-top: 60px;
  }

  .NewsDetail__FileLinks_List {
    padding: 0;
    list-style-type: none;
  }
  .NewsDetail__FileLink {
    margin-bottom: 1rem;
    display: block;
  }

  .NewsDetail__PressDefault {
    margin-top: 60px;
  }

  .NewsDetail__ContactPersonSlider {
    margin-top: 60px;
  }

  .Content--Main .Content--Main {
    @include mainColumn();
  }

  @media (max-width: 768px) {
    margin-top: 50px;
    h2 {
      font-size: 40px;
    }
  }
}
