.Content--OneColumnGrey,
.Content--OneColumnGreySearch {
  margin: 0 40px;
}

.ContentWrap--OneColumnGreySearch {
  max-width: 1275px;
}

.MainWrap--OneColumnGrey,
.MainWrap--OneColumnGreySearch {
  background-color: #f7f8fb;
  &:after {
    background-color: #f7f8fb;
    content: '';
    display: block;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 100%;
    z-index: -1;
  }
}
