$job-padding: 30px;
$job-spacing: $job-padding * 2;

.Jobs {
  margin-top: 60px;

  & > div {
    margin-bottom: 60px;
  }

  a[data-link-type='url'] {
    padding-left: 0;
  }

  a {
    text-decoration: none;
    display: block;
    color: black;

    &:hover {
      color: black;
      text-decoration: none;
      opacity: 0.6;
    }

    &::before {
      display: none !important;
    }
  }
}

.Job {
  border: 1px solid #cacaca;
  background: white;
  height: 100%;
  position: relative;

  .Job--Card {
    height: 100%;

    .JobItem__wrap {
      margin-bottom: $job-spacing;
    }

    .JobItem__ReadMore {
      bottom: 0;
      position: absolute;
      margin-top: 30px;
      font-size: 20px;
      color: $color-granite-weboptimized;
      font-weight: 500;
      margin-bottom: 0;
    }

    .JobItem__Date {
      font-size: 16px;
      line-height: 1;
      margin-bottom: 18px;
    }

    .JobItem--search {
      font-weight: 200;
      padding: 18px 30px 18px 30px;

      .JobItem__Type {
        position: relative;
        margin-bottom: 67px;
        left: 0;
        top: 18px;
        font-size: 20px;
      }

      .JobItem__Name {
        font-size: 28px;
        line-height: 1.29;
      }

      .JobItem__Title {
        font-size: 20px;
        line-height: 1.29;
        top: 18px;
        position: relative;
      }

      .JobItem__Workplace {
        font-size: 20px;
        line-height: 1.29;
        top: 18px;
      }
    }
  }
}

.Job-checkbox-ml {
  margin-left: -11px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
  }
}

form[id^='jobexchange'] {
  input[type='checkbox'] {
    display: inline-block;
    position: relative;
    -webkit-flex: 0 0 18px;
    flex: 0 0 18px;
    box-sizing: content-box;
    width: 18px;
    height: 18px;
    padding: 11px;
    line-height: 0;
    white-space: nowrap;
    cursor: pointer;
    vertical-align: bottom;
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
  }

  .mdc-checkbox {
    display: block;
  }

  .mdc-form-field label {
    font-size: 16px;
    font-weight: 400;
    line-height: 0;
    margin-top: 16px;
  }

  .form-check-label {
    padding-left: 12px;
  }

  .form-check {
    margin-bottom: 0;
  }

  p,
  label {
    margin-bottom: 0.5rem;
  }

  .mdc-checkbox--upgraded {
    margin-left: 11px;
  }

  span {
    display: inline-block;
    margin-bottom: 8px;
  }

  label[for='jobexchange-checkbox-1'] {
    margin-top: 10px;
  }

  .ContentElement.frame-type-textmedia {
    margin-bottom: 0;

    .Text {
      p {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.job-form-field label {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25rem;
  padding-left: 0;
}

.job-height {
  height: 100%;
}

.Jobs.row--big {
  .JobItem__ReadMore {
    bottom: 30px;
  }
}
