// Breakpoints
$xs-screen-width: 480px;
$sm-screen-width: 768px;
$lg-screen-width: 1200px;
$lgplus-screen-width: 1300px;

// Rebranding Colors
$color-granite-weboptimized: #7a7760;
$color-granat: #c60219;
$color-petrol-weboptimizied: #417f8f;

// Colors
$color-granite-light-weboptimized: #f0eee4;
$color-petrol: #4b92a4;
$color-petrol-dark: #23829b;
$color-ocher: #b39048;
$color-ocher-light: #f4eee3;
$color-red: #c86e6f;
$color-red-dark: #c35856;
$color-blueish: #7d90a3;
$color-black: #000;
$color-white: #fff;
$color-gray: #f7f8fb;
$color-gray-light: #eceef3;
$color-gray-dark-alt: #666666;
$color-gray-dark: #535151;

// Zindexes
$zindex-header: 50;
$zindex-choices-dropdown: 20;

// ----------------------------------------

// Body
$body-background-color: $color-white;
$link-decoration: none;

// Header
// header-height is changed in app.scss according to screen size
$header-height: var(--main-header-height);
$header-image-left-right-spacing-mobile: 22px;

// Mainnav
$mainnav-font-size: 19px;
$quicknav-font-size: 16px;
$mainnav-line-height: 25px;
$mainnav-overlay-close-icon-width: 15px;

// Agenda
$agenda-item-top-padding: 18px;
$agenda-item-bottom-padding: 30px;
$agenda-item-left-right-padding: 30px;

// form...
$color-form-label: #323334;

// Htwsportxml...
$tile-margin-top-bottom: 17px;

// Material Components (@material/...)
$mdc-theme-primary: $color-ocher;
$mdc-theme-secondary: $color-ocher;

// Pagination
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-padding-y-sm: 0;
$pagination-padding-x-sm: 0;
$pagination-padding-y-lg: 0;
$pagination-padding-x-lg: 0;
