.Rootline__List {
  margin: 40px 33px 12px 40px;
  padding: 0;
  list-style-type: none;
  line-height: 16px;
}
.Rootline__Item {
  display: inline-flex;
  align-items: center;
  margin: 0 5px 5px 0;
  font-size: 16px;
  min-height: 22px;

  i {
    position: relative;
    display: inline-block;
    margin-right: 8px;
    font-size: 14px;
    top: 2px;
  }

  &:first-child i {
    width: 0;
    opacity: 0;
    margin: 0;
  }

  a {
    display: inline-block;
    color: $color-black;
    text-decoration: none;
    hyphens: auto;

    &:hover,
    &:focus {
      color: $color-ocher;
      text-decoration: none;
    }
  }

  &:last-child {
    cursor: text;
    a {
      color: $color-ocher;
      pointer-events: none;
    }
  }
}

@media (max-width: 768px) {
  .RootlineNavigation {
    display: none;
  }
}

.Rootline__Item--Expandable {
  display: none;

  a {
    font-weight: 600;
    background-color: rgba($color-granite-weboptimized, 0.17);
    border-radius: 4px;
    padding: 3px;
    display: inline-block;
    line-height: 16px;
    &:hover {
      color: $color-black;
      background-color: rgba($color-granite-weboptimized, 0.4);
    }
  }
}

.Rootline__List--Collapsed {
  .Rootline__Item--Expandable {
    display: inline-flex;
  }

  .Rootline__Item--Collapsed {
    display: none;
  }
}
