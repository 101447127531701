// Display a cross with CSS only.
//
// $size  : px or em
// $color : color
// $thickness : px
@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: ($size - $thickness) / 2;
    left: 0;
    right: 0;
    height: $thickness;
    background: $color;
    border-radius: $thickness;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.tooltip {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);

  // It is not possible to hide this via variables.
  .tooltip-arrow {
    display: none;
  }
}

.tooltip-inner {
  hyphens: auto;
  text-align: left;

  @include media-breakpoint-up(md) {
    --bs-tooltip-max-width: 400px;
  }

  strong {
    display: block;
    font-size: 1.1em;
    padding-right: 25px;

    @include media-breakpoint-up(md) {
      padding-right: 0;
    }
  }

  * + p {
    margin-top: 5px;
  }

  p {
    margin-bottom: 0;
  }

  .close {
    @include cross(13px, #000, 1px);
    @include media-breakpoint-up(md) {
      display: none;
    }

    position: absolute;
    top: 9px;
    right: 10px;
  }
}

[data-bs-tooltip] {
  &:after {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-left: 3px;
    margin-bottom: 5px;
    border-radius: 1rem;
    background: $white url(../../Public/Icons/info.svg) no-repeat;
    background-size: 1rem;
  }
}
