.SliderLogo__Image {
  padding-top: 75%; // 4:3
  background: no-repeat center;
  background-size: contain;

  margin: 0 auto;
}

.SliderLogo__Wrap {
  border: 1px solid #cacaca;
  margin-bottom: 30px;
  padding: 10%;
}

.SliderLogo .row {
  margin: 0 -15px;
  & > div {
    padding: 0 15px;
  }
}

.SliderLogo {
  a[data-link-type] {
    @include hiddenIconLink();
    display: block;
  }
  a:hover {
    opacity: 0.8;
  }
}

.Logo--Slider {
  position: relative;

  .Logo__Item {
    width: 157px;
    min-height: 126px;
    margin-right: 30px;
  }
}

.Slider--Logo {
  .slick-prev,
  .slick-next {
    margin-top: -130px;
  }
}

@media screen and (max-width: 1300px) {
  .Slider--Logo {
    .slick-track {
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .Logo--Slider {
    .Logo__Image {
      width: 100%;
      height: auto;
      position: static;

      img {
        width: 100%;
        height: auto;
      }
    }

    .Logo__Item {
      margin-right: 0;
    }
  }
  .Slider--Logo {
    margin-bottom: 40px;
    .slick-list .slick-track .slick-slide {
      &:not(:first-child) {
        padding-left: 8px;
      }
      &:not(:last-child) {
        padding-right: 8px;
      }
    }
  }
}
