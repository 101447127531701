.HeaderImage__Wrapper {
  margin: 0 40px;
  position: relative;
  width: calc(100% - 80px);
  height: calc(100% - 52px - 76px + #{$header-height});
  min-height: 300px;

  &.hasMedia {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.HeaderImage {
  width: 100%;
  height: 100%;
  min-height: 200px;
  background: no-repeat 50% 50%;
  background-size: cover;
  object-fit: cover;
  display: flex;
  align-items: center;
  //background-size: contain;
  @media screen and (max-width: 768px) {
    align-items: flex-end;
  }

  img,
  picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.HeaderImage__Play {
  position: absolute;
}

@media screen and (max-width: 768px) {
  .HeaderImage__Wrapper.hasSticker {
    height: calc(100% - 34px - 76px - 60px + #{$header-height});
    margin-bottom: 20px;
    &.HeaderImage__WrapperLayout-Home {
      margin-bottom: 180px;
    }
  }
}

.HeaderWithoutImage,
.SearchHeader {
  border-top: 1px solid $color-gray-light;
}

.SearchHeader {
  max-width: 1275px;
  margin: 0 auto;
}

.HeaderImage__Title--TitleBox,
.HeaderWithoutImage__Title--TitleBox {
  font-size: 80px;
  font-weight: 400;
  margin: 5px 10px;
  line-height: 1.5;
  float: left;
  clear: both;
}

.SearchHeader__Title--TitleBox {
  font-size: 80px;
  font-weight: 200;
  margin: 5px 10px;
  line-height: 1.5;
  float: left;
  clear: both;
}

.HeaderImage__SubTitle--TitleBox,
.HeaderWithoutImage__SubTitle--TitleBox {
  font-size: 30px;
  font-weight: 300;
  margin: 8px 10px 5px;
  line-height: 1.7;
  float: left;
  clear: both;
}

.HeaderWithoutImage__Title--TitleBox,
.HeaderWithoutImage__SubTitle--TitleBox,
.SearchHeader__Title--TitleBox {
  margin-left: 0;
}

@include textWithBackground(
  'HeaderImage__TitleWrap--Title',
  $color-ocher,
  white
);
@include textWithBackground(
  'HeaderWithoutImage__TitleWrap--Title',
  $color-ocher,
  white
);
@include textWithBackground('HeaderImage__TitleWrap--Subtitle', white, black);
@include textWithBackground(
  'HeaderWithoutImage__TitleWrap--Subtitle',
  white,
  black
);

.HeaderImage {
  &__Title {
    &Wrap--Title {
      font-size: 32px;
      @media (min-width: 769px) and (max-width: 1100px) {
        font-size: 60px;
      }
      @media screen and (min-width: 1100px) {
        font-size: 80px;
      }
    }
  }
}
.HeaderImage__TitleBox {
  max-width: 75%;
  margin-left: 17px;
  position: absolute;
  left: 0px;
  bottom: 50%;
  transform: translate(0px, 50%);

  @media (min-width: 769px) and (max-width: 1100px) {
    .HeaderImage__Title {
      line-height: 1.2;
      .HeaderImage__TitleWrap--Title {
        font-size: 60px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    bottom: 0;
    transform: none;
  }
}

@media screen and (max-width: 768px) {
  .HeaderImage__Wrapper.hasMedia .HeaderImage__TitleBox {
    bottom: 103px;
  }
  .HeaderImage__Wrapper.hasMedia {
    height: auto;
    aspect-ratio: 1/1;
  }
}
.HeaderWithoutImage__TitleBox,
.SearchHeader__TitleBox {
  margin: 50px 20px 50px 22px;
  @media screen and (min-width: 769px) {
    margin-left: 40px;
  }

  &:after {
    clear: both;
    display: table;
    content: '';
  }
}

.HeaderImage__ScrollDown {
  position: absolute;
  left: 50%;
  bottom: -50px;
  z-index: 1;
}

.HeaderImage__ScrollDownLink {
  display: block;
  font-size: 26px;
  cursor: pointer;
  animation-name: scrolldownlink;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  text-align: center;
  height: 60px;
  width: 60px;
  margin-left: -30px;
  i {
    position: relative;
    top: 15px;
  }
}

@media (min-width: 769px) {
  .HeaderImage__PlayButton {
    position: absolute;
    bottom: 20px;
    left: 27px;
  }
}

@media (max-width: 768px) {
  .HeaderImage__ScrollDownLink {
    display: none;
  }
  .HeaderImage__Wrapper {
    width: calc(100% - 44px);
    height: calc(100% - 34px - 76px + #{$header-height});
    margin: 0 $header-image-left-right-spacing-mobile;
  }
  .HeaderImage__PlayButton {
    margin-left: 10px;
    img {
      margin-top: 9px;
    }
  }
  .HeaderImage__Title--TitleBox,
  .HeaderWithoutImage__Title--TitleBox,
  .SearchHeader__Title--TitleBox {
    font-size: 32px;
    line-height: 1.7;
  }
  .HeaderImage__SubTitle--TitleBox,
  .HeaderWithoutImage__SubTitle--TitleBox {
    font-size: 22px;
  }
}

@keyframes scrolldownlink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
