.ContentElement--StudyPathPicker {
  margin-bottom: 0;
}

.StudyPathPicker {
  position: relative;
  background: #dadada;

  color: white;
  text-align: center;

  .StudyPathPicker__Image {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .StudyPathPicker__TextContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30%;
  }

  .StudyPathPicker__Title {
    font-size: 50px;
    font-weight: 300;
    line-height: 1.4;

    display: inline-block;
    max-width: 2560 * 0.2;

    margin-bottom: 20px;
  }
  .StudyPathPicker__TitleWrap {
    background-color: $color-ocher;
    display: inline;
    padding: 2px 5px;
  }
  .StudyPathPicker__Description {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.44;
    margin-bottom: 20px;
    max-width: 2560 * 0.26;
    text-shadow: 0 0 4px black;
  }
  .StudyPathPicker__MoreLink {
    border: 1px solid white;
    background-color: #b2b2b2;
    display: inline-block;

    a {
      font-size: 20px;
      font-weight: 300;
      line-height: 2.55;

      padding: 0 50px;

      color: white;

      display: inline-block;
    }
  }
}
