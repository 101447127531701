.KeyFactBox {
  background: $color-ocher-light;
  color: black;
}
.KeyFactBox__Content {
  padding: 40px 0;
}
.KeyFactBox__Title {
  font-size: 20px;
  line-height: 1.476;
  margin-bottom: 18px;
}

.KeyFactBox__List {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.KeyFactBox__ListItem {
  padding: 0 40px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.476;
  a[data-link-type]:before {
    line-height: 1.81 !important;
  }

  margin-bottom: 12px;
  position: relative;

  > .KeyFactBox__LinkOuterWrap {
    padding: 0 40px;
    margin: 0 -40px;
    width: calc(100% + 80px);
    position: relative;
    display: inline-block;
  }
  // if TypoLink targets an inacessible page, no a.KeyFactBox__Link wrapper exists which breaks the markup.
  > .KeyFactBox__LinkInnerWrap {
    display: none;
  }
}

.KeyFactBox .KeyFactBox__Content {
  .KeyFactBox__Link {
    @include hiddenIconLink();
    position: absolute;

    right: 0;
    left: 0;
    top: 0;
    bottom: 0;

    opacity: 0;

    background-color: $color-ocher;
    color: white;

    padding: 0 40px !important;

    line-height: 2;
    transition: 0.5s opacity;
    text-decoration: none;
  }
}

.KeyFactBox__LinkInnerWrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.KeyFactBox__ListItem:hover {
  .KeyFactBox__Link {
    opacity: 1;
    color: white;
    text-decoration: none;
  }
}

.KeyFactBox .KeyFactBox__Content .SingleLinkHover {
  .KeyFactBox__LinkWrap,
  .KeyFactBox__TextWrap {
    @include hiddenIconLink();
    color: black;
    padding-left: 40px !important;
    margin: 0 -40px;
    width: calc(100% + 80px);
    display: inline-block;
  }
  .KeyFactBox__LinkWrap:hover {
    background-color: $color-ocher;
    transition: background-color 0.5s;
    text-decoration: none;
    .KeyFactBox__LinkTextWrap {
      color: white;
    }
  }
}

.KeyFactBox__ListItemHeader {
  font-size: 16px;
  font-weight: 400;
  line-height: 2.06;
  margin-bottom: 0;
}

@media (max-width: $sm-screen-width) {
  .KeyFactBox__ListItemHeader {
    font-size: 18px;
  }
}

.KeyFactBox__Contact {
  font-size: 26px;
  font-weight: 500;

  border-top: 1px solid $color-ocher;

  transition: 0.3s background-color;
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }

  a {
    display: block;
    padding: 25px 40px;
    color: $color-ocher;
    &:hover {
      text-decoration: none;
    }
  }
  i {
    display: inline-block;
    margin-right: 15px;
  }
}
