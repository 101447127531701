.BigFactBox {
  background: $color-ocher;
  color: $color-white;
  padding: 30px;

  a {
    font-weight: 800;
    color: $color-white;
  }
}

.BigFactBox__Text {
  h3 {
    font-size: 26px;
    font-weight: 300;
    line-height: 1.32;
    margin-bottom: 15px;
  }

  li,
  p {
    font-size: 20px;
  }
}

.BigFactBox__Text > :last-child {
  margin-bottom: 0;
}

.BigFactBox__Title {
  font-size: 20px;
  line-height: 1.45;
  margin-bottom: 18px;
}

.BigFactBox__List {
  list-style-type: none;
  padding-left: 40px;
}

.BigFactBox__ListItem {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.476;

  margin-bottom: 12px;

  &:before {
    content: '✓';
    width: 35px;
    display: inline-block;
    margin-left: -40px;
  }
}

.BigFactBox__Image {
  margin-top: 30px;
}
