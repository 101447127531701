$back-button-height: 55px;
// Includes the top/bottom border (box-sizing border-box)
$bottom-bar-height: $back-button-height + 10;
$topbar-height: $header-height;

.OffCanvasNavigation {
  @include HeaderNavigationOverlayBase();
}

.OffCanvasToggle {
  color: $color-black;
  font-size: 32px;
  width: 39px;
  height: 32px;

  margin-top: 14px;
  margin-right: 17px;

  display: block;

  line-height: 17px;
  text-align: center;

  border-radius: 0;
  border: 5px solid white;
  background: #fff;

  &:hover,
  &:focus {
    color: $color-black;
  }

  i {
    position: relative;
    top: -2px;
  }
}

.OffCanvasNavigation__TopBar {
  position: fixed;
  z-index: 1600;
  top: 0;
  left: 0;
  right: 0;
  height: $topbar-height;
  padding: 20px 18px 20px 20px;
  background: $color-white;
  a {
    margin: 0 10px;
  }

  .Header__Logo {
    width: auto;
    height: auto;
    margin: 0;

    a {
      margin: 0;
    }
  }
}

.OffCanvasNavigation__Search {
  float: left;
  a {
    position: relative;
    top: 0;

    margin: 0;
    padding: 5px;
    font-size: 26px;

      left: 65px;
      @media screen and (max-width: $lg-screen-width) {
        left: 0;
      }
    &:hover,
    &:focus {
      color: $color-black;
    }
    i {
      position: relative;
      top: 5px;
    }
  }

  .SearchSubmit--OffCanvas {
    cursor: pointer;
    transition: all 0.2s ease-out;
    transform: scale(1);
    &:hover {
      transform: scale(1.3);
    }
  }

  .SearchForm--OffCanvas {
    position: absolute;
    z-index: 100;
    left: 0;

    margin-top: -5px;

    width: 0;
    max-width: calc(100vw - 18px);
    overflow: hidden;

    transition: width 0.5s;

    input {
      margin-left: 80px;
      width: calc(100% - 80px);
      background: $color-white;
    }

    &.active {
      width: calc(100vw - 18px);
    }

    button {
      position: absolute;
      right: 0;
      top: 10px;
      padding-left: 0;
      padding-right: 0;
      background: none;
      border: none;

      font-size: 32px;
    }
  }
}

.OffCanvasNavigation__Close {
  float: right;
  margin-top: 8px;
  a {
    position: relative;
    top: -5px;
    right: -5px;
    margin: 0;
    padding: 5px 5px 5px 30px;

    img {
      width: 23px;
      height: auto;
      transition: all 0.2s ease-out;
      transform: scale(1);
      &:hover {
        transform: scale(1.3);
      }
    }
  }
}

.OffCanvasNavigation__BottomBar {
  top: $topbar-height;
  position: fixed;
  width: calc(100vw - 38px);
  z-index: 1500;
  height: $bottom-bar-height;
  margin: 0 18px 0 20px;
  border-bottom: 1px solid #dadadc;

  .OffCanvasNavigation__BackButton {
    height: $back-button-height;
    font-size: 26px;
    line-height: 30px;
    a {
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin: 0;
      background: $color-white;
      span {
        display: table-cell;
        vertical-align: middle;
        color: $color-ocher;

        &.parent-title {
          text-align: center;
        }

        &.arrow {
          i {
            display: block;
            margin-top: 5px;
          }
          width: 60px;
          flex: 1;
          padding: 10px 10px 10px 0;
        }
        &.placeholder {
          min-width: 35px;
          flex: 1;
          color: transparent;
        }
      }
    }

    .root {
      .parent-title {
        margin: 0 auto;
      }
    }
  }
}

.OffCanvasNavigation {
  &.Overlay {
    z-index: 1000;
    height: 100vh;
    border-top: none;
  }

  width: 100%;
  overflow-x: hidden;
  font-size: 23px;
  line-height: 1.3;
  font-weight: 300;

  .MainNavigation__Item--Language {
    float: right;
    margin: 10px 0 0 0;

    .LanguageNavigation {
      z-index: 1600;
    }

    > a:hover {
      color: $color-granite-weboptimized;
    }
  }

  ul.Navigation__List--OffCanvas {
    position: fixed;
    top: calc($topbar-height + $bottom-bar-height);
    bottom: 0;
    overflow-y: auto;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 21px;
    line-height: 24px;

    padding-top: 30px;

    transition: margin-left 0.5s;
    background-color: $color-white;

    list-style-type: none;
    li {
      margin: 15px 0;
      position: relative;
      &.current:before {
        content: '';
        width: 15px;
        height: 2px;
        background-color: $color-ocher;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &.current > a {
        color: $color-ocher;
      }

      // Hide first list item because its the offcanvas back button
      &:first-child {
        display: none;
      }
    }

    span.overviewTitle {
      display: block;
      width: calc(100% - 30px);
      color: $color-ocher;
      font-size: 18px;
      margin: 20px 22px 38px 22px;
    }
    a {
      width: calc(100% - 30px);
      margin: 0 13px;
      display: table;
      padding: 10px 2px 10px 7px;

      span {
        display: table-cell;
        vertical-align: middle;
      }

      span.arrow {
        width: 10px;
        i {
          margin-right: -5px;

          &::before {
            font-size: 15px;
          }
        }
      }
    }
    i {
      display: block;
      margin-top: 5px;
    }
  }

  a {
    color: $color-black;
    display: block;
    text-decoration: none;
  }
  a:hover {
    color: $color-ocher;
    text-decoration: none;
  }

  ul.Navigation__List--OffCanvas {
    z-index: 11;
    margin-left: 100%;
    ul {
      z-index: 20;
      ul {
        z-index: 30;
        ul {
          z-index: 40;
          ul {
            z-index: 50;
          }
        }
      }
    }
  }
  ul.Navigation__List--OffCanvas.active {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .OffCanvasToggle {
    margin-right: 0;
  }
}
