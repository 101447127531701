$media-item-playbutton-height: 110px;

.MediaItem__Link,
.FiveReasonsItem__Container {
  display: block;
  position: relative;
}

.MediaItem__Image {
  img,
  video,
  iframe {
    max-width: 100%;
    height: auto;
    width: 100vw;
  }

  iframe {
    display: block;
    aspect-ratio: 16/9;
    border: none;
  }
}

.MediaItem.MediaItem__HalfWidth {
  @media screen and (min-width: 768px) {
    max-width: 50%;
    .MediaItem__InfoBar {
      max-width: 50%;
    }
  }
}

.MediaItem__InfoBar {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  cursor: pointer;
}

.MediaItem__InfoBar .MediaItem__PlayButton {
  top: 50%;
  margin-top: -32px;
}

.MediaItem__PlayButton,
.FiveReasonsItem__PlayButton {
  position: absolute;
  left: 18px;
  bottom: 18px;
  width: $media-item-playbutton-height;
  height: $media-item-playbutton-height;

  border-radius: 100%;

  i {
    color: $color-white;
    text-align: center;
    line-height: 65px;
    width: 65px;
    font-size: 77px;
  }
  .MediaItem__PlayButton--Img {
    height: $media-item-playbutton-height;
  }
}

.MediaItem__PlayButton_Mobile {
  display: none;

  left: calc(50% - 32.5px);
  position: absolute;
  top: 35%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.MediaItem__Title {
  margin: 25px 25px 25px 25px;

  color: white;
}

.MediaItem__PlayButton + .MediaItem__Title {
  margin-left: 107px;
}

.MediaItem__MainTitle {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.4;
}

.MediaItem__SubTitle {
  font-size: 20px;
  font-weight: 500;
}

.MediaItem__Time {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  right: 18px;

  font-size: 16px;
  color: white;

  padding: 5px 19px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid white;
  border-radius: 15px;
}

.MediaItem--Single {
  max-width: 1280px;
}

.FiveReasonsItem__Link {
  margin: 14px 0 30px 0;
  font-weight: 500;
  font-size: 20px;

  a {
    &:before {
      display: none !important;
    }
  }
}

.Slider--FiveReasons {
  margin-top: 26px;
  .FiveReasonsItem__Image {
    img {
      max-width: 100%;
      height: auto;
      width: 100vw;
    }
  }
  .FiveReasonsItem__Title {
    margin-top: 21px;

    .FiveReasonsItem__MainTitle {
      font-weight: 300;
      font-size: 30px;
    }

    .FiveReasonsItem__SubTitle {
      margin: 10px 0 0 0;
      font-weight: 300;
      font-size: 20px;
      line-height: 23px;
    }
  }
}

@media screen and (max-width: 470px) {
  .MediaItem__PlayButton_Mobile {
    display: block;
  }
  .MediaItem__PlayButton {
    display: none;
  }
  .MediaItem__Title {
    margin: 6px;
  }
  .MediaItem__PlayButton {
    display: none;
  }
  .MediaItem__PlayButton + .MediaItem__Title {
    margin-left: 12px;
  }
}
/**
 * Enables flex layout for the MediaItem InfoBar and resets chilren for flex compatibility
 */
@mixin InfoBarFlex {
  .MediaItem__InfoBar {
    display: flex;
    flex-direction: row;
  }

  .MediaItem__PlayButton + .MediaItem__Title,
  .MediaItem__Title {
    margin: 0;
  }

  .MediaItem__Title {
    align-self: center;
  }

  .MediaItem__InfoBar .MediaItem__PlayButton {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    margin: 0;
    align-self: center;
  }
}

@media screen and (max-width: $xs-screen-width) {
  @include InfoBarFlex();

  .MediaItem__InfoBar {
    background: rgba(0, 0, 0, 0.7);
    max-height: 52px;
    padding: 5px 12px 5px 12px;
  }

  .MediaItem__MainTitle {
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 0.9rem;
  }

  .MediaItem__SubTitle {
    font-size: 0.7rem;
    line-height: 0.9rem;
  }

  .MediaItem__InfoBar .MediaItem__PlayButton,
  .FiveReasonsItem__PlayButton {
    margin-right: 12px;
    width: 30px;
    height: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .Slider--FiveReasons {
    .FiveReasonsItem__Title {
      width: 90vw;
      padding-bottom: 15px;

      .FiveReasonsItem__MainTitle {
        font-size: 16px;
        line-height: 21px;
      }

      .FiveReasonsItem__SubTitle,
      .FiveReasonsItem__Link {
        margin: 10px 0 0 0;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}

@media screen and (min-width: $xs-screen-width + 1) and (max-width: $sm-screen-width) {
  @include InfoBarFlex();

  .MediaItem__InfoBar {
    max-height: 83px;
    padding: 10px 17px 10px 17px;
  }

  .MediaItem__MainTitle {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  .MediaItem__SubTitle,
  .FiveReasonsItem__SubTitle,
  .FiveReasonsItem__Link {
    font-size: 1rem;
    line-height: 1rem;
  }

  .MediaItem__InfoBar .MediaItem__PlayButton,
  .FiveReasonsItem__PlayButton {
    margin-right: 17px;
    width: 65px;
    height: 65px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .Slider--FiveReasons {
    .FiveReasonsItem__Title {
      width: 90vw;
      padding-bottom: 15px;

      .FiveReasonsItem__MainTitle {
        font-size: 1.4rem;
        line-height: 1.4rem;
      }

      .FiveReasonsItem__SubTitle,
      .FiveReasonsItem__Link {
        margin: 10px 0 0 0;
      }
    }
  }
}

.MediaItem__Video {
  &.MediaItem__Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MediaItem__Play {
    position: absolute;
    width: 56px !important;
    height: auto !important;
  }
}
