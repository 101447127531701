// https://material-components.github.io/material-components-web-catalog/
@import '~@material/form-field/mdc-form-field';
@import '~@material/textfield/mdc-text-field';
@import '~@material/radio/mdc-radio';
@import '~@material/checkbox/mdc-checkbox';

$font-size: 20px;
$placeholder-color: rgba(0, 0, 0, 0.5);
$form-group-margin-bottom: 42px;
$form-group-error-text-height: 24px;
$form-group-margin-bottom-text-error: $form-group-margin-bottom +
  $form-group-error-text-height;
$form-group-margin-bottom-small: 14px;
$mdc-checkbox-left-padding: ($mdc-checkbox-touch-area - $mdc-checkbox-size) / 2;

@keyframes rotate360 {
  100% {
    transform: rotate(360deg);
  }
}

.mdc-text-field__input {
  box-sizing: content-box;
}

.FormElement__Footer {
  display: flex;
  justify-content: space-between;
}

.FormElement__CharacterCounter {
  margin-top: 0.25rem;
  margin-left: 1rem;
  white-space: nowrap;
}

.Plugin--Form {
  // Otherwise the highest form input's label will be sliced in half, while in active position
  margin-top: 9px;

  h1 {
    font-weight: 300;
  }

  * + h3 {
    margin-top: 3.7rem;
  }
  h3 {
    margin-bottom: 3rem;
  }

  h4 {
    color: $color-gray-dark-alt;
    font-size: 1.7rem;
    margin-bottom: 2.9rem;
  }

  h5 {
    font-size: 1.2rem;
    font-weight: normal;
    margin-bottom: 1.3rem;
  }

  h6 {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 1.3rem;
  }

  // H6 followed after h5 should be closer to h5 than to other elements
  // Reason why this was implemented with "~": formframework inserts random hidden session inputs in the form
  // so its not possible to select just by h5 + h6 (session input could be in between...)
  h5 ~ h6 {
    margin-top: -0.5rem;
  }
  [data-element-identifier]:not(h5) ~ h6 {
    margin-top: 0;
  }

  .Plugin--Form--SubmitErrorText {
    font-weight: 600;
    color: $color-granat;
  }

  .form-navigation--button {
    @include miniCallToAction();
    @include hiddenIconLink();
    &:focus {
      outline: none;
    }
    &:before {
      visibility: hidden;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .form-navigation {
    .btn-toolbar {
      .btn-group {
        width: 100%;
        > div {
          width: 100%;
        }
      }
    }
  }
}

.Plugin--Form__Submit .form-navigation--button:after {
  animation: rotate360 1s linear infinite;
  opacity: 0.7;
}

.mdc-text-field--date {
  .mdc-text-field__input {
    &::placeholder {
      transition: opacity 0.3s;
      opacity: 0;
      color: $placeholder-color !important;
    }
    &:focus {
      &::placeholder {
        opacity: 1;
      }
    }
  }
}

.mdc-text-field--date {
  .mdc-text-field__input {
    &::placeholder {
      transition: opacity 0.3s;
      opacity: 0;
      color: $placeholder-color !important;
    }
    &:focus {
      &::placeholder {
        opacity: 1;
      }
    }
  }
}

.mdc-text-field--date {
  .mdc-text-field__input {
    &::placeholder {
      transition: opacity 0.3s;
      opacity: 0;
      color: $placeholder-color !important;
    }
    &:focus {
      &::placeholder {
        opacity: 1;
      }
    }
  }
}

.mdc-form-group {
  .FormElement__OptionalLabel {
    display: none;
  }
  &.form-group--optional {
    .FormElement__OptionalLabel {
      display: block;
    }
  }

  .FormElement__OptionalLabel--Input {
    position: absolute;
    top: 21px;
    right: 0;
  }

  .FormElement__OptionalLabel--TextArea {
    position: absolute;
    bottom: 17px;
    right: 21px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.8);
  }

  &.mdc-form-group--date {
    .FormElement__OptionalLabel--Input {
      right: 40px;
    }
    .mdc-floating-label {
      transform: translateY(-160%) scale(0.6);
    }
  }

  // Align checkboxes and radiobuttons on the left (ignoring their padding based hover shadows)
  &.mdc-form-group--checkbox,
  &.mdc-form-group--radiobutton {
    margin-left: -$mdc-checkbox-left-padding;
  }
  &.mdc-form-group--radiobutton > label {
    margin-left: $mdc-checkbox-left-padding;
  }
  &.mdc-form-group--html {
    display: flex;
    flex-direction: column;

    & > div:first-child {
      order: 1;
    }
  }

  margin-bottom: $form-group-margin-bottom;
  &.has-error {
    &.mdc-form-group--text {
      margin-bottom: $form-group-margin-bottom-text-error;
    }

    .mdc-text-field {
      @include mdc-text-field-label-color($color-red);
      @include mdc-text-field-line-ripple-color($color-red);

      @include mdc-text-field-fullwidth-bottom-line-color(
        rgba($color-red, 0.4)
      );
      @include mdc-text-field-bottom-line-color(rgba($color-red, 0.4));

      @include mdc-text-field-textarea-stroke-color($color-red);

      &:not(.mdc-text-field--disabled) {
        .mdc-floating-label--float-above {
          color: $color-form-label;
        }
      }

      &:focus,
      &.mdc-text-field--focused {
        @include mdc-text-field-label-color($color-form-label);

        &:not(.mdc-text-field--disabled) textarea.mdc-text-field__input:focus {
          border-color: transparent;
        }
      }
    }

    .choices .choices__inner {
      border: solid 1px $color-red;
    }

    .error {
      color: $color-granat;
    }
  }

  textarea {
    min-height: 209px;
  }

  .choices {
    @include choicesSelect();
    margin-bottom: 0;

    .choices__list--dropdown .choices__item,
    .choices__inner {
      padding-left: 22px;
    }

    .choices__list {
      .choices__input {
        display: none;
      }
    }
  }

  .choices__item {
    font-weight: 300;
  }
}

.form-group--no-gap {
  margin-bottom: 0;
}

// All sub form-groups in this form-group have only a small vertical gap in between, (ideal for checkbox groups etc.)
.form-group--small-gap {
  margin-bottom: $form-group-margin-bottom - $form-group-margin-bottom-small;

  .mdc-form-group {
    margin-bottom: $form-group-margin-bottom-small;

    &.has-error.mdc-form-group--text {
      margin-bottom: $form-group-margin-bottom-small +
        $form-group-error-text-height;
    }
  }
}

.mdc-form-group--indent-like-checkbox-label {
  $font-character-left-gap: 3px;
  margin-left: $mdc-checkbox-touch-area - $mdc-checkbox-left-padding +
    $mdc-form-field-item-spacing + $font-character-left-gap;
}

.mdc-checkbox {
  @include mdc-checkbox-container-colors(
    $color-black,
    transparent,
    $color-ocher
  );

  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    background-color: transparent;

    .mdc-checkbox__inner-box {
      display: block;
    }
  }
  &::after,
  &::before {
    border-radius: 0;
  }
}

.mdc-checkbox__background {
  border-width: 1px;
  border-radius: 0;
  padding: 3px;
  &::after,
  &::before {
    border-radius: 0;
  }
}

.mdc-checkbox__inner-box {
  background-color: $color-ocher;
  display: none;
  width: 100%;
  height: 10px;
}

.mdc-text-field {
  @include mdc-text-field-line-ripple-color($color-ocher);
  @include mdc-text-field-label-color($placeholder-color);
  @include mdc-text-field-ink-color($color-black);

  @include mdc-text-field-textarea-fill-color(transparent);
  @include mdc-text-field-textarea-corner-radius(0);
  @include mdc-text-field-textarea-stroke-color($color-black);

  @include mdc-text-field-fullwidth-bottom-line-color($color-black);
  @include mdc-text-field-bottom-line-color($color-black);

  &:focus,
  &.mdc-text-field--focused {
    @include mdc-text-field-label-color($color-form-label);

    &:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
      border-bottom-color: transparent !important;

      @include mdc-text-field-hover-bottom-line-color($color-ocher);
    }
  }
}

.mdc-form-group
  .mdc-text-field:not(.mdc-text-field--disabled)
  textarea.mdc-text-field__input:focus {
  border-color: transparent;
}

.mdc-text-field .mdc-text-field__input {
  caret-color: $color-black;
  // Hide Edge specific input clear button
  &::-ms-clear {
    display: none;
  }
}

.mdc-text-field--textarea {
  transition: border 180ms;

  .mdc-text-field__input {
    margin-top: 40px;
  }
}

.mdc-form-field {
  @include mdc-radio-ink-color($color-ocher);
  @include mdc-radio-unchecked-stroke-color($color-black);
  @include mdc-radio-checked-stroke-color($color-ocher);
  @include mdc-radio-focus-indicator-color($color-ocher);

  label {
    font-size: $font-size;
    line-height: $font-size;
    font-weight: 300;
    margin-right: 38px;
    vertical-align: top;
    margin-bottom: 0;
  }
}

.mdc-radio__outer-circle {
  border-width: 1px;
}

.mdc-form-group.has-error .error.help-block {
  display: inline-block;
  margin-top: 0.25rem;
}

.mdc-text-field__input {
  font-weight: 300;
  font-size: $font-size;
}

.mdc-floating-label {
  font-weight: 300;
  font-size: $font-size;
}

.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  height: 58px;
}

.mdc-line-ripple.mdc-line-ripple--active {
  height: 1px;
}

.mdc-floating-label--float-above {
  font-weight: normal;
  transform: translateY(-160%) scale(0.6);
}

.mdc-text-field--textarea .mdc-floating-label {
  top: 32px;
}

.mdc-text-field--textarea .mdc-floating-label--float-above {
  transform: translateY(-39%) translateX(+4px) scale(0.623);
}

.mdc-form-group--textarea,
.mdc-form-group--radiobutton,
.mdc-form-group--checkbox {
  .mdc-text-field--disabled {
    label {
      color: rgba(0, 0, 0, 0.12);
    }
    .mdc-text-field--textarea,
    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle,
    .mdc-checkbox__background,
    .mdc-checkbox__inner-box {
      border-color: rgba(0, 0, 0, 0.12) !important;
    }
    .mdc-checkbox__inner-box {
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }
}
