.Content {
  .Content--Main {
    .Extension--Htwaddress.Plugin--PersonDetails {
      margin-bottom: 130px;

      figure.portrait {
        margin-bottom: 40px;
        img {
          max-width: 100%;
          height: auto;
        }
      }

      .curriculumText {
        margin-top: 40px;
        font-size: 20px;
        font-weight: 300;
        line-height: 31px;

        p {
          &:last-child {
            margin-bottom: 40px;
          }
        }
      }

      dl {
        margin: 40px 0 0;
        dt,
        dd {
          display: inline;
        }
        dd {
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
          }
        }
      }

      .socialMediaProfileLinks {
        margin-top: 40px;
        margin-bottom: 40px;
        ul {
          margin-top: 40px;
          display: inline;
          li {
            display: inline-block;
            &:first-child {
              margin-left: 15px;
            }
            &:not(last-child) {
              margin-right: 15px;
            }
            a {
              display: inline-block;
              margin-bottom: 5px;
              text-decoration: none;
              line-height: 1;

              img {
                vertical-align: text-bottom;
              }
            }
          }
        }
      }

      .personSubjectLinks {
        margin-top: 40px;
      }

      .personInformationContainer {
        background-color: #f4eee3;
        padding: 47px 131px 57px 40px;
        margin-bottom: 10px;

        h2 {
          margin-bottom: 20px;
        }

        p {
          font-size: 20px;
          font-weight: 300;
          line-height: 31px;
          margin-bottom: 0;
        }

        .personInformationTitle {
          margin-top: 23px;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
        }
        .personInformationData {
          word-break: break-word;
          margin-top: 12px;
        }
      }

      .Accordion {
        padding-bottom: 34px;

        .Accordion__ListItemBodyContent {
          padding: 18px 56px 0px 74px;
          line-height: 24px;
          .RteContent {
            display: flex;
            flex-direction: column;
            a {
              display: flex;

              &[data-link-type='page']:before {
                position: absolute;
                left: 45px;
              }

              &[data-link-type='url']:before {
                position: absolute;
                left: 45px;
              }

              &[data-link-type='file']:before {
                position: absolute;
                left: 45px;
              }

              span {
                display: flex;
                font-weight: 300;
              }
            }

            & > span {
              display: flex;

              &:before {
                content: '';
                display: block;
                flex-shrink: 0;
                width: 5px;
                height: 5px;
                background: $black;
                border-radius: 100%;
                margin-top: 0.6em;
                position: absolute;
                left: 50px;
              }
            }
          }
        }
      }
      .Accordion__Title {
        .header a {
          font-weight: 300;
          color: #212529;
        }
      }

      .PersonDetailPage__PublicationsLink {
        color: #212529;

        &:hover {
          text-decoration: none;

          .header span {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .Content--Right {
    .ImageBox {
      &.SocialMediaBox {
        padding-bottom: 36px;
      }
    }
  }
}

.google-maps-link {
  margin-top: 8px;
  display: block;
  margin-left: -2px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .BackTo {
    font-size: 1.5vw;
  }
}

// Tablet
@media (min-width: 769px) and (max-width: 991px) {
  .Content {
    padding-bottom: 35px;
    .Content--Main {
      .ContentElement--SubPage {
        &:last-child {
          margin-bottom: 2px;
        }
        .Extension--Htwaddress.Plugin--PersonDetails {
          margin-bottom: 0px;

          .PersonPicture {
            width: 50%;
          }

          .personInformationContainer {
            margin-bottom: 10px;
          }
          .curriculumTextMobile {
            .ContentElement--SubPage {
              margin-bottom: 10px;

              .Accordion {
                padding-bottom: 40px;
              }
            }
          }
        }
      }
    }
    .Content--Right {
      padding-right: 40px;
    }
  }
}

// Mobile
@media (max-width: 768px) {
  .Content {
    padding-bottom: 35px;

    .Content--Main {
      .ContentElement--SubPage {
        &:last-child {
          margin-bottom: 5px;
        }

        .Extension--Htwaddress.Plugin--PersonDetails {
          margin-bottom: 0px;

          .personInformationContainer {
            padding: 56px 48px 70px 37px;
            margin-bottom: 10px;

            h2 {
              font-size: 34px;
              line-height: 51px;
              margin-bottom: 20px;
            }

            p {
              font-size: 20px;
              line-height: 33px;
            }

            .personInformationTitle {
              margin-top: 23px;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
            }
            .personInformationData {
              word-break: break-word;
              margin-top: 12px;
            }
          }

          .curriculumTextMobile {
            .ContentElement--SubPage {
              margin-bottom: 10px;

              .Accordion {
                padding-bottom: 40px;
              }
            }
          }
        }
      }
    }
    .Content--Right {
      .ContentElement--ImageBox {
        .ImageBox {
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1110px) {
  .ImageBox {
    .socialMediaProfileLinks {
      li {
        img {
          height: 19px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .ShowOnlyOnMobile {
    display: block;
    padding: 0;

    img {
      margin: 0;
    }
  }
  .HideOnMobile {
    display: none;
  }
}
@media (min-width: 992px) {
  .ShowOnlyOnMobile {
    display: none;
  }
  .HideOnMobile {
    display: block;
  }
}
