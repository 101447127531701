.ContentWrap--SubPage {
  display: flex;
  margin-top: 120px;

  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }

  .Header {
    margin-left: 0;
  }
  &.ContentWrap--OneColumn {
    margin-top: 0;
  }
}

.ContentWrap--OneColumn > .ContentElement--SubPage {
  flex: auto;
}

.HeaderWithoutImage + .ContentWrap--SubPage {
  margin-top: 70px;
}

.ContentWrap--OneColumnNoRootLine {
  @extend .ContentWrap--OneColumn;
}

.LeftColumn,
.SubNavigation--SubPage {
  width: calc(15vw + 20px);
  max-width: 290px;
  margin: 0 65px 0 10px;
  padding-bottom: 150px;
  z-index: 2;
}

.LeftColumn {
  margin-right: 15px;
}

.Content--SubPage {
  position: relative;
  // Flexbox children have min-width: auto by default which doesnt calculate the correct width with calc
  min-width: 0;
  width: calc(100% - 15vw - 60px);
  flex: 1;

  .ContentSize {
    position: relative;
  }

  .ContentSize--Small {
    width: 100%;
    max-width: 539px;
  }
  .ContentSize--Medium {
    width: 100%;
    max-width: 718px;
  }
  .ContentSize--Large {
    width: 100%;
  }
  .ContentSize--Full {
    width: calc(100% + 40px);
  }
}

.ContentWrap--OneColumn .Content--SubPage,
.ContentWrap--ThreeColumn .Content--SubPage {
  width: calc(100% - 30px);
}

@media (min-width: 769px) {
  .ContentWrap--OneColumn .Content--SubPage,
  .ContentWrap--ThreeColumn .Content--SubPage {
    width: calc(100% - 15vw - 60px);
  }
}

@media (max-width: 768px) {
  .SubNavigation--SubPage {
    display: none;
  }

  .Content--SubPage,
  .ContentWrap--OneColumn .Content--SubPage,
  .ContentWrap--ThreeColumn .Content--SubPage {
    width: 100%;

    .ContentSize--Small {
      width: 100%;
      max-width: 539px;
    }
    .ContentSize--Medium {
      width: 100%;
      max-width: 718px;
    }
    .ContentSize--Large,
    .ContentSize--Full {
      width: 100%;
    }
  }
}
@media (max-width: 991px) {
  .Content--SubPage .Content--Right {
    margin-top: 38px;
  }
}
@media (max-width: 768px) {
  .Plugin--EventForm,
  .Content--SubPage {
    & > .row > [class*='col-'] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (min-width: 992px) {
  .Content--SubPage {
    & .Content--Right [class*='ContentSize--'] {
      width: calc(100% - 30px);
      margin-right: 0;
    }
  }
}

@media (min-width: 2200px) {
  .ContentWrap--SubPage {
    position: relative;
    width: calc(1500px + 365px + 365px - 30px);
    margin-left: auto;
    margin-right: auto;
  }
  .Content--SubPage {
    width: 1500px;
  }
}

// Redesign "FHGR Optimierungen 2024, 4446.828, 4446.77

@media (max-width: $sm-screen-width) {
  .HeaderImage__Wrapper.hasSticker {
    aspect-ratio: 4 / 5;
    height: auto;
  }
  .HeaderImage__TitleBox {
    max-width: 85%;
  }
  .HeaderImage__Title--TitleBox,
  .HeaderWithoutImage__Title--TitleBox,
  .SearchHeader__Title--TitleBox {
    line-height: 37.8px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .modernizr-boxdecorationbreak .HeaderImage__TitleWrap--Subtitle {
    font-size: 18px;
    line-height: 29px;
    padding: 2px 2px;
  }
  .HeaderImage__SubTitle--TitleBox {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
  }
  .modernizr-boxdecorationbreak .HeaderImage__TitleWrap--Title {
    font-size: 27px;
    padding: 0px 4px;
  }
  .Content {
    padding-bottom: 26px;
  }
  .ContentWrap--SubPage {
    margin-top: 26px;
    padding-bottom: 26px;
  }
  .MobileContentAccordionHeader {
    font-size: 22.5px;
    font-weight: 400;
    padding-top: 16px;
    padding-bottom: 16px;
    line-height: 30.3px;
  }
  .MobileContentAccordionHeaderWrapper--active .MobileContentAccordionHeader {
    font-weight: 500;
  }
  .MobileContentAccordionHeaderWrapper {
    h2 {
      display: none;
    }
  }
  .ContentElement--SubPage {
    h4 {
      font-weight: 500;
    }
    h4,
    .blog .more {
      font-size: 1.3125rem; // 21px
      font-weight: 300;
      line-height: 1.15;
      margin: 0 0 1rem 0;
    }
    .blog .slick-track {
      column-gap: 0;
    }
    .FactBox {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    p,
    li {
      font-size: 18px;
      line-height: 24.3px;
    }
    p:not(:last-child),
    ul:not(:last-child),
    ol:not(:last-child) {
      margin-bottom: 16px;
    }
    &:not(:first-child) header > h3,
    header > h3,
    header .h3,
    h3,
    .h3 {
      // 4446.879 removed here see
    }
    &:not(:first-child) .Text > .Text__Text > h3,
    .Text > .Text__Text > .h3 {
      font-size: 22.5px;
      margin-top: 16px !important;
      margin-bottom: 16px !important;
    }
    .Links__List:last-child {
      margin-bottom: 0;
    }
    .Links__ListItem {
      font-size: 18px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .KeyFactBox__Content {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .KeyFactBox__ListItem {
      padding: 0 26px;
      margin-bottom: 16px;
    }
    .Slider--FiveReasons {
      margin-top: 0;
    }
    .Slider--FiveReasons {
      .FiveReasonsItem__Title {
        margin-top: 16px;
        padding-bottom: 16px;
      }
      .FiveReasonsItem__MainTitle {
        font-size: 18px;
        font-weight: 500;
        line-height: 24.3px;
      }
      .FiveReasonsItem__SubTitle {
        font-size: 18px;
        line-height: 24.3px;
      }
    }
    .Slider__PageCounter {
      font-size: 18px;
      line-height: 24.3px;
    }
    .Slider__CurrentPage {
      font-size: 18px;
    }
    //.Slider .slick-prev,
    //.Slider .slick-next {
    //  height: 40px;
    //  display: flex;
    //  align-items: center;
    //  svg {
    //    width: 10px;
    //  }
    //}
    .Accordion__Title {
      padding-top: 0;
      padding-bottom: 0;
      h3,
      .h3 {
        font-size: 1.375rem; // 22px
        line-height: 1.15;
        font-weight: 300;
        margin-top: 1rem;
        margin-bottom: 0.75rem;
      }
      &:after {
        font-size: 17px;
        font-weight: 600 !important;
      }
    }
    .Accordion__ListItemBodyContent {
      padding-top: 0;
      padding-bottom: 16px;
    }
    &.MobileContentAccordionHeaderWrapper:not(:last-child) {
      margin-bottom: 0;
    }
    .ContentSize--Medium {
      margin-bottom: 26px;
    }
    .Person--Scroller {
      .PersonFunction,
      .Person__Name,
      .Person__Phone,
      .Person__Email {
        font-size: 16px;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        height: auto;
        min-height: auto;
        line-height: 21.6px;
      }
      .Person__Name {
        font-size: 18px;
        padding-bottom: 16px;
      }
      .Person__Text {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
  .MobileContentAccordionHeaderWrapper--active .MobileContentAccordionHeader {
    margin-bottom: 26px;
  }
  .ContentElement--SubPage {
    p {
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    &:not(:last-child) {
      margin-bottom: 26px;
    }
  }

  .AgendaItem--teaser {
    padding: 16px 30px;
    .AgendaItem__wrap {
      line-height: 21.6px;
    }
    .AgendaItem__Type {
      font-size: 16px;
      line-height: 21.6px;
      margin-bottom: 16px;
      min-height: initial;
    }
    .AgendaItem__Day {
      line-height: 1;
    }
    .AgendaItem__Title {
      padding-bottom: 53px;
    }
  }

  .Laboratory--GridItem {
    padding-top: 16px;
    padding-bottom: 42px;
    .Laboratory__MoreLink {
      font-size: 18px;
      line-height: 24.3px;
      bottom: 24px;
      line-height: 24.3px;
    }
    .Laboratory__Title {
      font-size: 18px;
      line-height: 24.3px;
      font-weight: 400;
    }
    .Laboratory__Description {
      font-size: 18px;
      line-height: 24.3px;
    }
  }

  .modernizr-boxdecorationbreak
    .Testimonial--Slider
    .Testimonial__Item
    .Testimonial__Quote,
  .modernizr-no-boxdecorationbreak
    .Testimonial--Slider
    .Testimonial__Item
    .Testimonial__Quote {
    line-height: 28.3px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .ShowMore.position-relative {
    padding-top: 26px;
    font-size: 18px;
    line-height: 24.3px;
    font-weight: 500;
    &:after {
      visibility: hidden;
    }
  }
  .modernizr-boxdecorationbreak .Testimonial--Slider {
    padding-bottom: 16px;
  }
  .SliderLogo__Wrap {
    margin-bottom: 16px;
  }
  .Testimonial__Text {
    margin: 12px 0 0;
  }
  .Testimonial--Slider .Testimonial__Item {
    border-bottom: none;
  }
  .modernizr-boxdecorationbreak
    .Testimonial--Slider
    .Testimonial__Item
    .Testimonial__Quote,
  .modernizr-no-boxdecorationbreak
    .Testimonial--Slider
    .Testimonial__Item
    .Testimonial__Quote {
    line-height: 0;
    padding: 0.7px 2.8px 1px;
  }
  .Testimonial__Person__Container {
    margin: 14px 0 0 !important;
  }
  .Slider--Logo {
    margin-bottom: 0;
  }
  .Slider--Events .slick-slide {
    padding-bottom: 18px;
  }
  .Slider--MediaItem .slick-list {
    padding-bottom: 16px;
  }
  .modernizr-boxdecorationbreak .Testimonial--Slider .Testimonial__Item,
  .modernizr-no-boxdecorationbreak .Testimonial--Slider .Testimonial__Item {
    border-bottom: none;
  }
  .MobileContentAccordionHeader:after {
    font-size: 17px;
    font-weight: 600 !important;
  }
}

.Testimonial--Slider {
  .Testimonial__Quote {
    font-weight: 300;
  }

  .Testimonial__Person {
    font-weight: 300;
  }

  .row--big .column {
    border: 1px solid red;
  }
}
.AgendaItem--teaser {
  .AgendaItem__Location {
    i {
      padding-right: 3px;
    }
  }
}

.Person--Scroller {
  .PersonFunction,
  .Person__Name,
  .Person__Phone,
  .Person__Email {
    font-weight: 300;
    a {
      font-weight: 500;
    }
  }
}

.Person--Scroller {
  .Person__Phone,
  .Person__Email {
    font-size: 16px;
  }
}

.FactBox__Text strong,
.ContentElement--Accordion strong {
  font-weight: 600;
}

.ContentElement--SubPage:has(.FactBox),
.ContentElement--SubPage:has(.KeyFactBox) {
  &:not(:last-child) {
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 480px) {
  .modernizr-boxdecorationbreak
    .Testimonial--Slider
    .Testimonial__Item
    .Testimonial__Person,
  .modernizr-no-boxdecorationbreak
    .Testimonial--Slider
    .Testimonial__Item
    .Testimonial__Person {
    line-height: 21.6px;
  }
  .modernizr-boxdecorationbreak
    .Testimonial--Slider
    .Testimonial__Item
    .Testimonial__Text,
  .modernizr-no-boxdecorationbreak
    .Testimonial--Slider
    .Testimonial__Item
    .Testimonial__Text {
    margin-top: 15px;
  }
}

// End of redesign "FHGR Optimierungen 2024, 4446.828, 4446.77
