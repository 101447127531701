.Content--Home {
  margin-top: 6.6vw;
  position: relative;

  .ContentElement--SubPage {
    &.frame-type-template_lead {
      margin-bottom: 80px;
    }
    &.frame-type-textmedia,
    &.frame-type-list {
      margin-bottom: 140px;
    }
    &:last-child {
      margin-bottom: 40px;
    }

    &.frame-type-template_entrylinks {
      margin-bottom: 0;

      .ContentElement--Home {
        margin-bottom: 0;
      }
    }

    .FactBox {
      padding: 40px;
      .FactBox__Text {
        h3 {
          line-height: 30px;
          margin-bottom: 10px;
          &:before {
            content: '';
            background-image: url(../../../Public/Frontend/Images/htw-svgs/info.svg);
            display: inline-flex;
            width: 25px;
            height: 25px;
            padding: 3px;
            margin-right: 10px;
          }
        }
        a {
          color: #7a7760;
          padding-left: 25px !important;
          display: inline-block;

          &[data-link-type='page']:before,
          &[data-link-type='url']:before,
          &[data-link-type='file']:before {
            margin-right: 5px;
            display: inline-block !important;
            vertical-align: bottom;
          }
        }
      }
    }
  }

  .frame-type-template_mediaslider,
  .frame-type-template_sliderfivereasons {
    @include media-breakpoint-up(md) {
      margin: {
        left: 40px;
        right: 40px;
      }

      .Slider--MediaItem,
      .Slider--FiveReasons {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .ContentWrap--Home {
    .Content--Home {
      padding-bottom: 25px;
      .Content--Main {
        .ContentElement--SubPage {
          &.frame-type-template_lead {
            margin-bottom: 50px !important;
          }
          &.frame-type-template_entrylinks {
            .ContentElement--InformationLinks {
              .InformationLinks {
                padding: 50px 22px;

                .InformationLinks__Header {
                  text-align: left;
                }
                .InformationLinks__Wrapper {
                  .InformationLinks__Link {
                    &:not(:last-child) {
                      margin-bottom: 22px;
                    }
                  }
                }
              }
            }
          }
          &.frame-type-textmedia,
          &.frame-type-list {
            &:not(:last-child) {
              margin-bottom: 50px !important;
            }
            .FactBox {
              margin: 0;
              padding: 40px 22px;
            }

            .SliderWrapper {
              padding-bottom: 30px;
            }
            .GridItem--Laboratory:last-child {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $sm-screen-width) {
  .ContentWrap--Home {
    .Content--Home {
      .Content--Main {
        .ContentElement--SubPage {
          &.frame-type-textmedia,
          &.frame-type-list {
            .FactBox {
              margin: 0 -22px;
            }
          }
        }
      }
    }
  }
}
