@mixin hyphenate {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin removeHyphenate {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
}

@mixin textWithBackground($class, $background, $foreground) {
  .modernizr-boxdecorationbreak .#{$class} {
    background: $background;
    color: $foreground;

    padding: 5px 8px;

    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }

  .modernizr-no-boxdecorationbreak .#{$class} {
    background: $background;
    color: $foreground;

    padding: 5px 0;

    box-shadow: 8px 0 0 $background, -8px 0 0 $background;
  }

  html:not(.modernizr-no-boxdecorationbreak):not(.modernizr-boxdecorationbreak)
    .#{$class} {
    background: $background;
    color: $foreground;

    padding: 5px 8px;

    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }
}
