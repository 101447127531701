.Sticker {
  position: absolute;
  padding-left: $header-image-left-right-spacing-mobile;
  padding-right: $header-image-left-right-spacing-mobile;
}
@media screen and (min-width: 769px) {
  .Sticker {
    width: 0;
    top: calc(100vh - 320px);
    right: calc(8vw);
    padding-left: 0;
    padding-right: 0;
  }
}

.Sticker__Button {
  background-color: $color-granat;
  color: white;
}
.Sticker__Button:hover {
  color: white;
  opacity: 0.8;
}
.Sticker__Button--ScrolldownLink:hover {
  cursor: pointer;
}

a.Sticker__Link {
  &:hover {
    text-decoration: none;
  }
  &:before {
    display: none !important;
  }
}

.Sticker__EventDate {
  display: inline-flex;
  flex-direction: column;
}

.Sticker__EventLocation {
  hyphens: auto;
}

@media screen and (max-width: 768px) {
  .Sticker {
    position: relative;
    width: 100%;
    top: calc(100% - 60px);
  }
  .Sticker__Button {
    box-shadow: 0 3px 4px rgba($color-black, 0.3);
    width: 100%;
    min-height: 60px;
    height: auto;
    text-align: center;
  }

  .Sticker__EventDate {
    align-items: center;
  }

  .Sticker__Text {
    font-size: 18px;
    line-height: 24.3px;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-height: 60px;
    font-weight: 500;

    > * {
      flex: 1;
    }
  }
  .Sticker__EventLocation {
    text-align: left;
  }
}
@media screen and (min-width: 769px) {
  .Sticker__Button {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
    transition: transform 0.3s;
    width: calc(105px + 6vw);
    height: calc(105px + 6vw);
  }

  .Sticker__Button:hover {
    transform: scale(1.05);
  }

  .Sticker__Text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    padding: 18px;
    width: 100%;
    transition: transform 0.3s;
  }

  .Sticker__Button:hover .Sticker__Text {
    transform: translateY(-50%) scale(0.97) rotate(-8deg);
  }
}

.ContentElement.frame-type-evento_link {
  margin: 0;
}

@media screen and (max-width: 991px) {
  .Sticker__Button {
    font-size: 20px;
  }
  .Content--Main {
    .Sticker__Button {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 992px) {
  .Sticker__Button {
    font-size: 18px;
  }
  .Content--Main {
    .Sticker__Button {
      font-size: 18px;
    }
  }
}

// START of lead text sticker styles
.Sticker__Lead {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (max-width: 768px) {
  .BigText--WithSticker {
    margin-bottom: 0;

    p {
      margin-bottom: 1.2rem;
    }
  }
  .Sticker__Lead {
    position: relative;
    top: auto;
  }
}

@media screen and (min-width: 769px) {
  .BigText.BigText--WithSticker {
    max-width: 66%;
    min-height: 210px;
    position: relative;
    z-index: 1;

    > p {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .StickerContainer__Lead {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .Sticker__Lead {
    width: auto;
    top: 50%;
    transform: translateY(-50%);

    & .Sticker__Button {
      position: relative;
    }
  }
}

// END of lead text sticker styles
