.Scroller--Gallery {
  position: relative;
  height: 333px;
  width: 100%;
  max-width: 100%;
}

.Scroller--Gallery .Scroller__List {
  display: flex;
  padding-bottom: 15px;
}

.Gallery__Image {
  width: 540px;
  height: 303px;

  margin-right: 10px;
}
