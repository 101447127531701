$header-padding-top-bottom: 28px;
$main-wrapper-margin-sm: 22px;

.MobileContentAccordionHeader {
  display: none;
  @media (max-width: $sm-screen-width) {
    display: flex;
  }

  font-size: 17px;
  border-bottom: 1px solid #cacaca;
  padding-top: $header-padding-top-bottom;
  padding-bottom: $header-padding-top-bottom;
  text-decoration: none;

  margin-left: -1 * $main-wrapper-margin-sm;
  margin-right: -1 * $main-wrapper-margin-sm;
  padding-left: $main-wrapper-margin-sm;
  padding-right: $main-wrapper-margin-sm;

  border-left-width: 0;
  border-right-width: 0;

  &:hover {
    text-decoration: none;
  }

  &::after {
    // Icon font settings
    font-family: 'htw-arrows';
    font-style: normal;
    font-weight: bold;
    font-variant: normal;
    text-transform: none;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;

    @include iconArrowDown();

    padding: 5px 0 0 5px;
    margin-left: auto;
  }
}

// The top border of accordion header should only be shown for the very first header and
// the header directly after an active header
.MobileContentAccordionHeaderWrapper:first-child .MobileContentAccordionHeader,
  // This sets the border for all headers after an active header, few lines below we'll further filter this
.MobileContentAccordionHeaderWrapper--active ~ .MobileContentAccordionHeaderWrapper .MobileContentAccordionHeader {
  border-top: 1px solid #cacaca;
}

// Only the first header after an active header should have a top border
.MobileContentAccordionHeaderWrapper--active
  ~ .MobileContentAccordionHeaderWrapper
  ~ .MobileContentAccordionHeaderWrapper
  .MobileContentAccordionHeader {
  border-top: none;
}

// Overrides subnavigation color which has a high specificity
.ContentElement .MobileContentAccordionHeader {
  color: $color-black;
}

// Active header styles
.MobileContentAccordionHeaderWrapper--active .MobileContentAccordionHeader {
  color: $color-ocher;
  margin-bottom: $main-wrapper-margin-sm;

  &::after {
    @include iconArrowUp();
  }
}

.MobileContentAccordionHeaderWrapper--active .MobileContentAccordionHeader {
  margin-bottom: 60px;
}

@media (max-width: $sm-screen-width) {
  .MobileContentAccordionHeaderWrapper {
    // An accordion header wrapper can not have a top margin, otherwise the whole accordion would be screwed up
    // We have to use important because some contentElement styles use :not(:first-child) stuff with very high priority
    margin-top: 0 !important;
  }

  .MobileContentAccordionHeaderParent {
    // An accordion header parent must always be visible, otherwise the accordion item wouldnt "have" a header
    display: block !important;
  }

  .MobileContentAccordionHeaderWrapper:not(.MobileContentAccordionHeaderWrapper--active) {
    margin-bottom: 0;
  }

  // Only show ContentElements that are after an active accordionHeader
  // ContentElements after an inactive accordionHeader should be hidden
  .MobileContentAccordionHeaderWrapper ~ .ContentElement {
    display: none;
  }
  .MobileContentAccordionHeaderWrapper--active ~ .ContentElement {
    display: block;
  }
  .MobileContentAccordionHeaderWrapper--active
    ~ .MobileContentAccordionHeaderWrapper
    ~ .ContentElement {
    display: none;
  }

  // The accordionHeaders are in contentElements too, those headers have to be always visible
  // But other potential content of those contentElements has to be hidden
  main.Content .ContentElement.MobileContentAccordionHeaderWrapper {
    display: block;
  }
  .MobileContentAccordionHeaderWrapper:not(.MobileContentAccordionHeaderWrapper--active) {
    & > *:not(header) {
      display: none;
    }
    header > *:not(.MobileContentAccordionHeader) {
      display: none;
    }
  }

  .ProjectDetailIntroduction,
  .ProjectDetailKeyFactBox {
    padding-bottom: 4.7vw;
  }
}
