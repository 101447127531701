@mixin mainColumn {
  // The ThreeColumns-layout shows a right column starting at the lg breakpoint
  // We have to disable certain layout features of the main column
  // that only are intended if the column connects to the right window border
  @media screen and (min-width: map_get($grid-breakpoints, 'lg')) {
    // ContentSizeFull should not overlap the right column
    @include disableContentSizeFull();

    // Sliders should not have a right overflow
    @include sliderHideRightOverflow();
  }
}
