.BigText {
  @include removeHyphenate();
  max-width: calc((2560px / 2) - 100px);
  font-size: 4vw;
  line-height: 1.4;
  font-weight: 300;
}

@media (max-width: 768px) {
  .BigText {
    font-size: 32px;
  }
}
@media (min-width: 768px) {
  .BigText {
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media (min-width: 1200px) {
  .BigText {
    font-size: 50px;
  }
}

@media (max-width: 400px) {
  .BigText {
    font-size: 28px;
  }
}

.frame-type-template_lead {
  &:not(:first-child) {
    margin-bottom: 50px;
  }
}
