.studyplan {
  width: 100%;
  max-width: 718px;
  padding-bottom: 6px;
  h2 {
    font-size: 41px;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .teaser {
    display: grid;
    gap: 1rem;
    @include media-breakpoint-up(lg) {
      gap: 1.875rem;
      grid-template-columns: 3fr 3fr;
    }

    &__title {
      width: 15rem;
      margin-bottom: 1rem;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &__left {
      grid-row: 1;
      grid-column: 1;

      img {
        object-fit: cover;
        width: 100%;
        height: auto;
      }
    }

    &__right {
      grid-row: 2;

      @include media-breakpoint-up(lg) {
        grid-row: 1;
        grid-column: 2;
        padding: 1.75rem 0;
      }
    }
  }

  .start {
    &__title {
      font-size: 3.75rem;
      margin-top: 2rem;

      @include media-breakpoint-down(md) {
        font-size: 1.25rem;
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  .btn {
    line-height: 2.8;
    padding: 0 20px;
  }

  .legend {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      padding-bottom: 0.8rem;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &__icon {
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 1rem;
    }

    &__label {
      margin-top: -5px;
    }
  }

  .Overlay {
    display: flex;
    flex-direction: column;

    > .header {
      height: 65px;
      border-bottom: 1px solid #e5e5e5;
      padding: 0 #{1.25rem - $input-btn-padding-x};

      @include media-breakpoint-up(md) {
        padding: 0 #{2.5rem - $input-btn-padding-x};
        height: 86px;
      }
    }
    > .footer {
      border-top: 1px solid #e5e5e5;
      height: 50px;

      .btn {
        width: 100%;
        height: 100%;

        @include media-breakpoint-up(lg) {
          width: auto;
          height: auto;
        }
      }
      .button-wrapper {
        @include media-breakpoint-down(md) {
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }

      @include media-breakpoint-up(lg) {
        height: 108px;
      }
    }

    .elective-module-container {
      @include media-breakpoint-down(md) {
        height: 100%;
        > div:nth-child(1) {
          overflow-y: scroll;
          order: 2;
        }

        > div:nth-child(2) {
          z-index: 2;
          order: 1;
        }
      }
    }
  }
  .footer-container {
    margin: 0 auto;
    max-width: 960px;
    height: 100%;
  }
  .content {
    margin: 0 auto;
    padding: 71px 0;
    max-width: 960px;

    > .row {
      gap: 24px;
    }

    @include media-breakpoint-down(md) {
      height: 100%;
      padding: 0;
    }
  }
  .content-wrapper {
    width: 100%;
    overflow-y: scroll;
    padding: 0 0.9375rem;
    height: auto;
    flex: 1;

    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .accordion {
    &-item {
      border: {
        left: none;
        right: none;
      }

      &.open {
        .icon-state {
          transform: rotateX(180deg);
        }
      }
    }
    &-header {
      padding-left: 0;
      padding-right: 0;

      border-bottom: none;
      h4 {
        font-size: 1.25rem;
        margin-bottom: 0;
      }
    }

    &-content {
      padding: 1rem 0;
    }
  }
  .drop-active {
    background: rgba(0, 0, 0, 0.09);
  }

  .drop-allowed {
    background-color: rgba(84, 253, 84, 0.2);
  }
  .module {
    padding: 0 15px;
    height: 100%;
    color: $white;
    align-items: center;
    min-height: 62px;

    &.inactive {
      opacity: 0.5;
    }

    .icon {
      max-height: 24px;
    }
  }

  .placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;

    &-container {
      aspect-ratio: 16 / 9;
      border: 1px dashed #5a5a5a;

      @include media-breakpoint-down(md) {
        height: 62px;
        width: 100%;
      }
    }
  }

  .overview {
    .icon {
      width: 25px;
      fill: #7a7760;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    .accordion + .accordion {
      margin-top: -1px;
    }

    .link-box {
      font-size: 1.25rem;
    }
  }

  .semester-link {
    font-size: 1.25rem;
  }

  // Utilities
  .space-y > * + * {
    margin-top: 43px;
  }
  .space-y-sm > * + * {
    margin-top: 8px;
  }
  .pointer {
    cursor: pointer;
  }
  .h-full {
    height: 100%;
  }
}

@supports not (aspect-ratio: 16 / 9) {
  .studyplan .placeholder-container {
    height: 140px;
    width: 100%;

    @include media-breakpoint-down(md) {
      height: 62px;
      width: 100%;
    }
  }
}
