.Overlay--MainNavigation {
  @include HeaderNavigationOverlayBase();
}

.MainNavigation__List {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.MainNavigation__Item--Language,
.MainNavigation__Item--Quick {
  position: relative;

  a {
    display: inline-block;
    padding-right: 25px;

    i {
      position: absolute;
      top: 3px;
      right: 0;
      margin-left: 5px;
    }
  }
}

.MainNavigation__Item {
  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    text-decoration: underline;
  }
}

.MainNavigation__Item--Level1 {
  font-size: $mainnav-font-size;
  line-height: $mainnav-line-height;
  float: left;
  margin: 24px 18px 0 14px;
  &.MainNavigation__Item--Page.active > a {
    font-weight: 600;
  }

  &:last-child {
    margin-right: 0px;
  }
}

.AdditionalNavigation__List .MainNavigation__Item--Level1 {
  font-size: $quicknav-font-size;
  margin: 18px 18px 0 26px;
}

.MainNavigation__Item--Level2,
.MainNavigation__Item--Level3,
.MainNavigation__Item--Level4 {
  &.active > a {
    color: $color-ocher;
  }
  &.current > a {
    position: relative;
    &:before {
      content: '';
      width: 30px;
      height: 2px;
      background-color: $color-ocher;
      position: absolute;
      left: -45px;
    }
  }
}
.MainNavigation__Item--Level2.current > a:before,
.MainNavigation__Item--Level3.current > a:before {
  top: 0.6em;
}
.MainNavigation__Item--Level4.current > a:before {
  top: 0.7em;
}

.AdditionalNavigation__List .MainNavigation__Item--Search {
  font-size: 18px;
}

.AdditionalNavigation__List .MainNavigation__Item--Quick {
  margin-right: 0;
}

.MainNavigation__SubNavigation {
  clear: both;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 100px 0;
}

.MainNavigation__Column {
  margin: 0 0 20px;
  width: 33.33%;
  padding: 0 27px;
  float: left;

  &:nth-child(3n + 1) {
    clear: both;
  }
}
@media (max-width: 1200px) {
  .MainNavigation__Column {
    width: 50%;

    &:nth-child(2n + 1) {
      clear: both;
    }
  }
}

.MainNavigation__Item--Level2 {
  font-size: 28px;
  line-height: 35px;
  font-weight: 300;
  color: #343434;
  margin-bottom: 80px;

  & > a {
    display: block;
    margin-bottom: 30px;
  }
}

.MainNavigation__Item--Level3 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;
  color: rgba(52, 52, 52, 0.6);
  margin-bottom: 30px;

  & > a {
    display: block;
    margin-bottom: 10px;
  }
}
.MainNavigation__Item--Level4 {
  font-size: 16px;
  line-height: 23px;
  color: #343434;
  & > a {
    display: block;
    margin-bottom: 6px;
  }
}
