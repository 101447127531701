$workshops-overlay-header-height: 135px;
$workshops-overlay-header-height-xs: 110px;
$workshops-overlay-desktop-container-width: 1020px;
$workshops-overlay-right-summary-breakpoint: $workshops-overlay-desktop-container-width -
  200;

.Extension--Htwevents {
  .header {
    height: auto;
  }

  h1.header1 {
    font-size: 80px;
    font-weight: 400;
  }
  .FactBox_GoogleMapsLink {
    color: $color-granite-weboptimized;
    font-weight: 400;
    &:before {
      font-size: 18px;
    }
  }

  .ical-link {
    color: #7a7760;
    display: block;
    font-weight: normal;
  }

  .PartnerLogos__Header {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.27;
    margin-bottom: 6px;
  }

  .PageTeaser__Header {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.27;
    margin-bottom: -1.3333vw;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.Plugin--EventsList .Event,
.SolrResults .Event {
  .Event__CloseToYouBadge {
    display: none;
  }

  &.Location--Close {
    color: $color-petrol-weboptimizied;
    opacity: 1;
    float: none;

    a {
      color: $color-petrol-weboptimizied;
    }

    .Event__CloseToYouBadge {
      display: inline;
    }
  }

  a {
    @include hiddenIconLink();
  }
}

.WorkshopsOverlay__Container {
  padding: 0 20px;
  height: 100%;
  margin: auto;
  width: 100%;
  @media screen and (min-width: $workshops-overlay-desktop-container-width) {
    width: $workshops-overlay-desktop-container-width;
  }
}

.WorkshopsOverlay__Header {
  height: $workshops-overlay-header-height-xs;
  border-bottom: 1px solid $color-gray-light;

  @media screen and (min-width: $xs-screen-width) {
    height: $workshops-overlay-header-height;
  }
}

.WorkshopsOverlay__HeaderHeadingAligner {
  height: 100%;
  display: flex;
  align-items: center;
}

.WorkshopsOverlay__Body {
  height: calc(100% - #{$workshops-overlay-header-height-xs});

  @media screen and (min-width: $xs-screen-width) {
    height: calc(100% - #{$workshops-overlay-header-height});
  }
}

.WorkshopsOverlay .WorkshopsOverlay__HeaderHeading {
  font-size: 26px;
  margin-bottom: 0;
  @media screen and (min-width: $workshops-overlay-right-summary-breakpoint) {
    font-size: 40px;
  }
}

.WorkshopsOverlay {
  position: fixed;
  overflow: hidden;
}

.WorkshopsOverlay__Close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 40px;
  color: black;

  &:hover {
    text-decoration: none;
  }

  @media screen and (min-width: $workshops-overlay-right-summary-breakpoint) {
    top: 30px;
    right: 30px;
  }
}

.WorkshopsOverlay__ColumnSpacer {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.WorkshopsOverlay__ColumnLeft {
  position: relative;
  flex: 1;
  height: 100%;
  padding-right: 10px;
  @media screen and (min-width: $workshops-overlay-desktop-container-width) {
    flex-basis: 560px;
  }
}

.WorkshopsOverlay__ColumnTitleShadow {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: -5px;
  overflow: hidden;
  width: 100%;
  height: 70px;
}
.WorkshopsOverlay__ColumnTitleShadowEmitter {
  width: 100%;
  height: 60px;
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.6);
}

.WorkshopsOverlay__ColumnTitle {
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 24px;
  hyphens: auto;
}

.WorkshopsOverlay__ColumnBody {
  height: calc(100% - 60px);
}

.WorkshopsOverlay__ColumnRight {
  display: none;
  flex-basis: 390px;
  @media screen and (min-width: $workshops-overlay-right-summary-breakpoint) {
    display: block;
  }
}

.WorkshopsOverlay__ColumnTitle--Light {
  font-weight: 300;
}

.WorkshopsOverlay__ColumnLeftBody {
  height: calc(100% - 60px);
}

.WorkshopsOverlay__ScrollArea {
  width: calc(100% - 5px);
  padding-right: 5px;
  height: 100%;
  overflow-y: auto;
}

.WorkshopsOverlay__Group {
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 20px;
  }
}

.WorkshopsOverlay__GroupTitle {
  padding: 17px 0;
  font-size: 14px;
  font-weight: 500;
}

.WorkshopsOverlay__WorkShop {
  border-top: 1px solid $color-gray-light;
  padding: 20px 0 17px 0;
  min-height: 105px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $xs-screen-width) {
    flex-direction: row;
  }
}

.WorkshopsOverlay__WorkShopDate {
  font-size: 14px;
}

.WorkshopsOverlay__WorkShopAlert {
  color: $color-granat;
  font-size: 14px;
  font-style: italic;
}

.WorkshopsOverlay__WorkShopColumnLeft {
  margin-bottom: 10px;

  @media screen and (min-width: $xs-screen-width) {
    flex: 1;
    margin-bottom: 0;
    padding-right: 10px;
  }
}

.WorkshopsOverlay__WorkShopColumnRight {
  width: 120px;
}

.WorkshopsOverlay__WorkshopSelectButton {
  cursor: pointer;
  color: $gray-900;
  font-size: 14px;
  width: 100%;
  text-align: center;
  border: 1px solid $color-black;
  background-color: $color-white;
  padding: 14px 0;

  &:disabled {
    cursor: default;
    opacity: 0.3;
    color: $gray-900;
  }
}

.WorkshopsOverlay__WorkshopSelectButton--Selected {
  color: $color-white;
  background-color: $color-black;
}

.WorkshopsSummary__Workshop {
  border-bottom: 1px solid $color-gray-light;
  padding: 17px 0;
}

.WorkshopsSummary__Date {
  font-weight: 500;
  font-size: 14px;
}

.WorkshopsSummary__Time {
  font-size: 14px;
}

.WorkshopsSummary__Alert {
  color: $color-granat;
  font-size: 14px;
  font-style: italic;
}

.WorkshopsSummary__Footer {
  margin-top: 13px;
  display: flex;
  align-items: center;
}
.WorkshopsSummary__FooterLeft {
  flex: 1;
}
.WorkshopsSummary__FooterRight {
  width: 120px;
  margin-left: 15px;
}

@mixin WorkshopsButton {
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: $color-white;
  border: 1px solid $color-granite-weboptimized;
  background-color: $color-granite-weboptimized;
  padding: 14px 0;
}

.OpenWorkshopsOverlayButton {
  @include WorkshopsButton();
  font-size: 20px;
}

.WorkshopsSummary__Button {
  @include WorkshopsButton();
}

.WorkshopsOverlay__SummaryOverlayBackground {
  display: none;
  position: absolute;
  width: 120%;
  top: 0;
  right: -20px;
  height: 100%;
}

.WorkshopsOverlay__SummaryOverlayBackground--Open {
  display: block;
  background-color: rgba(0, 0, 0, 0.3);

  @media screen and (min-width: $workshops-overlay-right-summary-breakpoint) {
    display: none;
  }
}

.WorkshopsOverlay__SummaryOverlay {
  top: 0;
  right: -20px;
  transform: translate(100%, 0);
  transition: transform ease-out 0.2s;
  position: absolute;
  width: 90%; //315px;
  padding: 0 23px 23px 23px;
  height: 100%;
  background-color: $color-white;

  @media screen and (min-width: $workshops-overlay-right-summary-breakpoint) {
    display: none;
  }
}
.WorkshopsOverlay__SummaryOverlay--Open {
  transform: translate(0%, 0);
}
.WorkshopsOverlay__SummaryOverlayButton {
  cursor: pointer;
  position: absolute;
  right: 100%;
  top: 75px;
  padding: 11px;
  font-size: 27px;
  line-height: 27px;
  color: $color-white;
  background-color: $color-granite-weboptimized;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-width: 0px;
}

.WorkshopsOverlay__SummaryOverlayTitle {
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 24px;
  hyphens: auto;
}

.event-gone-error {
  padding: 40px 0;
  font-size: 24px;
  color: $color-red;
}
