.cc_container {
  .cc_btn {
    background-color: $color-granite-weboptimized;
    color: white;
    border-radius: 0;
  }
  .cc_message a {
    color: #c86e6f !important;
  }
}
