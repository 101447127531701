.StudyPathSticky__MoreLink {
  margin-left: 40px;
  a {
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    color: white;
    line-height: 2.8;
  }
}

.StudyPathSticky__MoreBox {
  position: fixed;
  border-radius: 6px;
  right: 24px;
  bottom: 24px;
  color: white;
  background: $color-red;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  z-index: 1000;

  max-width: 25vw;

  transition: opacity 1s;
  opacity: 1;
}

.StudyPathSticky__MoreBox--hidden {
  opacity: 0;
  pointer-events: none;
}

.StudyPathSticky__Title--MoreBox {
  font-size: 32px;
  font-weight: 300;
}

.StudyPathSticky__Subtitle--MoreBox {
  font-size: 16px;
  font-weight: 300;
}
.StudyPathSticky__Titles--MoreBox {
  background-size: cover;
}
