@mixin ContentSize--Any {
  .ContentSize--Small,
  .ContentSize--Medium,
  .ContentSize--Large,
  .ContentSize--Full,
  .ContentSize--Big {
    @content;
  }
}

@mixin disableContentSizeFull {
  .ContentSize--Full {
    max-width: 100%;
  }
}
