.icon-link {
  @include iconLink();
}

.hidden-icon-link {
  @include hiddenIconLink();
}

.Content {
  a,
  button {
    &[data-link-type='page'],
    &[data-link-type='url'],
    &[data-link-type='overlay'],
    &[data-link-type='file'] {
      @include iconLink();
      &:before {
        line-height: 1.44 !important;
      }
    }

    &[data-link-type='page']:before {
      @include iconArrowRight();
    }

    &[data-link-type='url']:before {
      @include iconArrowUpRight();
    }

    &[data-link-type='file']:before {
      @include iconFiles();
    }
  }

  p {
    a {
      display: inline-block;
      &[data-link-type='page'] {
        @include hiddenIconLink();
      }
    }
  }
}
