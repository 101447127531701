// Bootstrap defaults for links are set with a strong selector (https://github.com/twbs/bootstrap/issues/19402)
@mixin Link() {
  &:not([href]):not([tabindex]) {
    @content;
  }
}

@mixin LinkColor($color, $hoverColor: $color) {
  @include Link() {
    @if $color {
      color: $color;
    }

    @if $hoverColor {
      &:hover,
      &:focus {
        color: $hoverColor;
      }
    }
  }
}
