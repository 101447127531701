@charset "UTF-8";

@font-face {
  font-family: 'htw-font-2019';
  src: url('../../Fonts/htw-font-2019.eot');
  src: url('../../Fonts/htw-font-2019.eot?#iefix') format('embedded-opentype'),
    url('../../Fonts/htw-font-2019.woff') format('woff'),
    url('../../Fonts/htw-font-2019.ttf') format('truetype'),
    url('../../Fonts/htw-font-2019.svg#htw-font-2019') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin htwIcon() {
  font-family: 'htw-font-2019' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
  @include htwIcon();
}

[class^='icon-']:before,
[class*=' icon-']:before {
  @include htwIcon();
}

.icon-address:before {
  content: '\61';
}
.icon-back:before {
  content: '\3e';
}
.icon-blog:before {
  content: '\6e';
}
.icon-call-to-action:before {
  content: '\3c';
}
.icon-close:before {
  content: '\39';
}
.icon-down:before {
  content: '\31';
}
.icon-edit:before {
  content: '\61';
}
.icon-extern:before {
  content: '\32';
}
.icon-facebook:before {
  content: '\66';
}
.icon-contact:before {
  content: '\63';
}
.icon-chat:before {
  content: '\6e';
}

@mixin iconArrowRight {
  content: '\3c';
}
.icon-arrow-right:before {
  @include iconArrowRight();
}

@mixin iconArrowUpRight {
  content: '\3c';
  display: inline-block;
  transform: rotate(-45deg);
}
.icon-arrow-up-right:before {
  @include iconArrowUpRight();
}

@mixin iconArrowLeft {
  content: '\3e';
}
.icon-arrow-left:before {
  @include iconArrowLeft();
}
.icon-arrow-down:before {
  content: '\31';
}
.icon-play:before {
  content: '\70';
}
.icon-plus:before {
  content: '\2b';
}
.icon-search:before {
  content: '\73';
}
.icon-slider-arrow:before {
  content: '\33';
}
.icon-twitter:before {
  content: '\74';
}
.icon-flickr:before {
  content: '\72';
}
.icon-google:before {
  content: '\67';
}
.icon-instagram:before {
  content: '\69';
}
.icon-left:before {
  content: '\38';
}
.icon-linkedin:before {
  content: '\6c';
}
.icon-location:before {
  content: '\65';
}

@mixin iconNewsletter {
  content: '\75';
}
.icon-newsletter:before {
  @include iconNewsletter();
}
.icon-pdf:before {
  content: '\6f';
}
.icon-place:before {
  content: '\63';
}
.icon-publications:before {
  content: '\62';
}
.icon-right:before {
  content: '\34';
}
.icon-search:before {
  content: '\73';
}
.icon-slider-arrow:before {
  content: '\33';
}
.icon-snapchat:before {
  content: '\7a';
}
.icon-twitter:before {
  content: '';
  display: inline-block;
  background-image: url(../../Images/twitter.svg);
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;

  @media screen and (max-width: $xs-screen-width) {
    background-image: url(../../Images/twitter-white.svg);
  }
}

.FooterWrap--Landingpage .icon-twitter:before {
  background-image: url(../../Images/twitter-white.svg);
}

.icon-webshop:before {
  content: '';
  display: inline-block;
  background-image: url(../../Images/webshop.svg);
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}

@mixin iconFiles {
  content: '\64';
}
.icon-files:before {
  @include iconFiles();
}

.icon-up:before {
  content: '\36';
}
.icon-xing:before {
  content: '\78';
}
.icon-youtube:before {
  content: '\79';
}
.icon-job-search:before {
  content: '\6a';
}
.icon-trophy2:before {
  content: '';
  display: inline-block;
  background-image: url(../../Images/trophy2.svg);
  background-repeat: no-repeat;
  width: 30px;
  height: 43px;
}
