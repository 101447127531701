.GridList--Laboratory {
  margin-bottom: -(1.3333vw * 2);
}

.GridItem--Laboratory {
  position: relative;
  background: $color-petrol;
  background-clip: content-box;
  &:hover {
    opacity: 0.8;
  }
}

.GridItem--Laboratory {
  a,
  a:hover {
    color: white;
    text-decoration: none;
  }

  a:hover {
    .Laboratory__MoreLink {
      text-decoration: underline;
    }
  }
}

.Laboratory--GridItem {
  padding: 30px 30px 70px 30px;

  color: white;

  .Laboratory__Title {
    font-size: 30px;
    line-height: 1.27;
    font-weight: 300;
    margin: 0 0 16px 0;
  }

  .Laboratory__Description {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.476;
    margin-bottom: 15px;
  }

  .Laboratory__MoreLink {
    position: absolute;
    bottom: 40px;
    font-size: 20px;
    font-weight: 500;
    line-height: 2.55;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .Laboratory--GridItem .Laboratory__Title {
    font-size: 3vw;
  }
}

@media (min-width: 768px) {
  .GridItem--Laboratory {
    &.column {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 1.3333vw * 2;
    }
  }

  .Laboratory--GridItem {
    padding: 30px 30px 30px 30px;

    .Laboratory__Description {
      margin-bottom: 55px;
    }

    .Laboratory__MoreLink {
      line-height: 30px;
    }
  }
}
