.Scroller--Testimonial {
  position: relative;
  height: 486px + 32px;
  width: 100%;
  max-width: 100%;
}

.Scroller--Testimonial .Scroller__List {
  display: flex;
  padding-bottom: 15px;
}

.Testimonial--Slider {
  position: relative;

  .Testimonial__Item {
    width: 995px;
    min-height: 486px;
    margin-right: 10px;
  }

  .Testimony__Body {
    position: absolute;
    left: 20px;
    right: 100px;
    bottom: 30px;
  }

  .Testimonial__Image {
    position: absolute;
    top: 0;
    left: 0;

    z-index: -1;
  }

  .Testimonial__Text {
    padding: 20px 20px 20px 300px;
  }

  .Testimonial__Quote {
  }
  .Testimonial__Person {
  }

  .Testimonial__ShortQuote,
  .Testimonial__Quote,
  .Testimonial__Person {
    font-size: 20px;
    font-weight: 300;
    line-height: 2;
    padding: 0 0;
    display: inline;
  }

  .Testimonial__ShortQuote {
    display: none;
  }
}

.slick-slide {
  &:first-child {
    .Testimonial__NoImage {
      .Testimonial__Text {
        padding-left: 0px;
      }
    }
  }
  .Testimonial__NoImage {
    .Testimonial__Item {
      width: 663px;
      min-height: auto;
      margin-right: 70px;
    }
    .Testimonial__Text {
      padding: 20px 20px 20px 70px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .Slider--Testimonial {
    .slick-track {
      width: 100%;
    }
  }
  .Testimonial--Slider {
    .Testimonial__Item {
      width: 100%;
    }
    .Testimonial__ShortQuote,
    .Testimonial__Quote,
    .Testimonial__Person {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 768px) {
  .Testimonial--Slider {
    .Testimonial__Image {
      width: 100%;
      height: auto;
      position: static;

      img {
        width: 100%;
        height: auto;
      }
    }

    .Testimonial__Item {
      width: 100%;
      min-height: 0;
    }

    .Testimonial__Text {
      position: absolute;
      bottom: 0;
      left: 0;
      height: auto;
      margin: 20px;
      padding: 0;
    }

    &.showFull {
      .Testimonial__Quote,
      .Testimonial__Person {
        display: inline;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .modernizr-boxdecorationbreak,
  .modernizr-no-boxdecorationbreak {
    .Testimonial--Slider {
      padding-bottom: 32px;
      .Testimonial__Item {
        border-bottom: 1px solid #cacaca;
        hyphens: auto;

        .Testimonial__Image {
          img {
            width: calc(100vw - 44px);
            height: calc(100vw - 44px);
            object-fit: cover;
            object-position: 0% 0%;
          }
        }

        .Testimonial__Text {
          margin: 5px 0 0 0;
          position: relative;
          font-size: 16px;
        }
        .Testimonial__Quote,
        .Testimonial__Person {
          font-size: 16px;
        }
        .Testimonial__Quote {
          line-height: 36px;
          padding-top: 4px;
          padding-bottom: 4px;
        }
        .Testimonial__Person {
          line-height: 23px;
          padding: 4px 0;
        }
        .Testimonial__Person__Container {
          margin: 5px 0;
        }
      }
    }
  }
}

@include textWithBackground('Testimonial__ShortQuote', $color-ocher, white);
@include textWithBackground('Testimonial__Quote', $color-ocher, white);
@include textWithBackground('Testimonial__Person', white, black);
