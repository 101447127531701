.skip-link {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: white;
  color: black;
  text-decoration: none;
  &:focus {
    left: 0;
    outline: 3px solid #4a90e2;
  }
}
