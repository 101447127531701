.Links__Title {
  font-size: 26px;
  font-weight: 300;
  line-height: 1.27;
  margin-bottom: 20px;
}

.Links__List {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
}

.Links__ListItem {
  margin-bottom: 10px;
  @include miniCallToAction();

  a {
    @include hiddenIconLink();
    color: $white;
    align-items: center !important;

    span {
      display: inline-block;
      vertical-align: middle;

      svg {
        display: block;
      }
    }
  }
}

.ical-link {
  color: #7a7760;
  display: block;

  &:before {
    content: '';
    background: url(../../Images/calendar.svg) no-repeat;
    background-size: 18px 18px;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 4px 10px 0 0;
    vertical-align: top;
  }
}
