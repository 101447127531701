.ShowMore {
  padding-right: 20px;

  &:after {
    @include iconArrowBase();
    @include iconArrowNext();
    position: absolute;
    top: 5px;
    right: -5px;
  }

  position: absolute;
  bottom: calc(100% + 5px);
  right: 40px;
  font-size: 20px;
  color: $color-granite-weboptimized;
}

.Content--SubPage .ShowMore {
  right: 0;
}

.BackTo {
  @include iconLink();
  display: inline-block;
  font-size: 20px;
  font-weight: 300;
  margin-left: 28px;
  padding-left: 30px !important;
  white-space: nowrap;
  &:before {
    @include iconArrowLeft();
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  .ShowMore {
    right: 0;
  }
}
