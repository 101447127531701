.Solr__FilterItem {
  margin-bottom: 20px;

  input.Form__Input--Search:focus + i {
    display: none;
  }

  input.Form__Input--Search {
    padding-left: 36px;
  }
  .CustomSelect {
    .choices__inner {
      border: solid 1px #000000;
      color: #000000;
    }
  }
  .Form__Input {
    border-bottom: solid 1px #000000;
  }
  .icon-search {
    &::before {
      color: #000000;
    }
  }
}

.SolrResults {
  margin-top: 60px;

  & > div {
    display: flex;
    margin-bottom: 60px;

    & .SolrResult {
      width: 100%;
      height: auto;
    }
  }
}

.SolrResult {
  border: 1px solid #cacaca;

  height: 100%;

  background: white;
}

.SolrResult__Type {
  padding: 10px 30px;
  border-bottom: 1px solid $color-gray-light;
  font-size: 32px;
  font-weight: 200;
}

.SolrResult--News,
.SolrResult--File,
.SolrResult--Page {
  a {
    display: block;
    text-decoration: none;
    color: black;

    &:hover {
      color: black;
      text-decoration: none;
      opacity: 0.6;
    }

    &:before {
      display: none !important;
    }
  }
}

// Content in a solr result item should be vertically wrapped
@mixin SolrResultWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

// Padding in a solr result item
$solr-result-item-padding: $agenda-item-left-right-padding;

// This is the min spacing between the result item content and the "readmore" link
$solr-result-item-content-bottom-spacing: $solr-result-item-padding * 2;

.SolrResult {
  .AgendaItem__wrap {
    margin-bottom: $solr-result-item-content-bottom-spacing;
  }
  .AgendaItem__ReadMore {
    // We use the flex layout to align this item to the bottom, so no position/bottom tricks needed
    position: static;
    bottom: unset;
    // All free space from the flexlayout will be used as top margin (https://stackoverflow.com/a/31006659)
    // Makes sure that the readmore is vertically aligned to the bottom
    margin-top: auto;
  }
}
.SolrResult--Page,
.SolrResult--File {
  a {
    @include SolrResultWrapper;
    padding: $solr-result-item-padding;
    padding-top: $agenda-item-top-padding;
  }
}
.SolrResult--News .AgendaItem--search {
  @include SolrResultWrapper;
  padding-bottom: $solr-result-item-padding;
  height: 100%;
}

.SolrResult--Page .SolrResult__Content {
  padding-bottom: $solr-result-item-content-bottom-spacing;
  .result-content {
    // We only want the spacing from $solr-result-item-content-bottom-spacing
    margin-bottom: 0;
  }
}

.SolrResult__Content .results-type {
  @include agendaItemType();
}

.SolrResult--Page,
.SolrResult--File,
.SolrResult--News {
  height: 100%;
  .Event {
    height: 100%;
  }
  a {
    height: 100%;
  }

  .SolrResult__Content {
    .results-topic,
    .results-topic a {
      font-size: 24px;
      font-weight: 200;
      color: $color-black;
    }
  }
}

.SolrResult--File {
  .SolrResult__BottomLink {
    word-break: break-all;
  }
}

.SolrResult__BottomLink {
  color: $color-granite-weboptimized;
}

.SolrResult--Person {
  .Person__Image {
    width: 100%;
    object-fit: cover;
  }

  .Person__Text {
    padding: 15px 30px 37px 30px;
  }

  .Person__Name {
    font-size: 32px;
    line-height: 38px;
    font-weight: 300;
    margin-bottom: 16px;
    a {
      color: $color-black;
      &:hover {
        display: inline;
        border-bottom: 1px solid black;
        text-decoration: none;
      }
    }
  }

  .Person__Title {
    margin-bottom: 12px;
  }

  .PersonFunction {
    margin-top: 8px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 11px;
    min-height: 40px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Person__Phone a,
  .Person__Email a {
    line-height: 20px;
    display: inline-block;
    word-break: break-all;
  }
}

.solr-pagination {
  margin-left: -5px;
  margin-right: -5px;
}

.pagination {
  display: block;

  &:after {
    clear: both;
    display: table;
    content: '';
  }

  li,
  .page-item {
    float: left;

    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #cacaca;
    margin: 5px;
    text-align: center;

    a,
    .page-link {
      color: black;

      border: none;
      background: unset;
      width: 45px;
      height: 45px;
      line-height: 45px;

      display: block;
      &:hover {
        color: $color-white;
        text-decoration: none;
      }
    }

    &.active,
    &:hover {
      background: $color-petrol;
      border: $color-petrol solid 1px;

      a,
      .page-link,
      .dots {
        color: $color-white;
      }
    }
    .iconarrow-next {
      position: relative;
      &::before {
        position: relative;
        bottom: -2px;
        left: 1px;
      }
    }
    .iconarrow-back {
      position: relative;
      &::before {
        position: relative;
        bottom: -2px;
        right: 1px;
      }
    }
  }
}
