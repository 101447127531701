.MediaGrid__Image {
  padding-top: 75%; // 4:3
  background: no-repeat center;
  background-size: contain;

  margin: 0 auto;
}

.MediaGrid__Wrap {
  border: 1px solid #cacaca;
  margin-bottom: 30px;
  padding: 10%;
}

.MediaGrid .row {
  margin: 0 -15px;
  & > div {
    padding: 0 15px;
  }
}

.MediaGrid {
  a[data-link-type] {
    @include hiddenIconLink();
    display: block;
  }
  a:hover {
    opacity: 0.8;
  }
}
