.SubNavigation__List {
  position: -webkit-sticky;
  position: sticky;
  top: calc($header-height + 40px);

  list-style-type: none;
  padding: 0;
  text-align: right;
}

.SubNavigation__Item {
  position: relative;

  font-size: 20px;
  font-weight: 300;
  line-height: 1.476;
  margin-bottom: 22px;
  margin-top: 12px;

  a {
    text-decoration: none;
    color: $color-black;
    transition: color 0.6s;
    &:hover,
    &:focus {
      color: $color-ocher;
      text-decoration: none;
    }
  }

  &:after {
    content: '';
    width: 30px;
    height: 2px;
    background-color: $color-ocher;
    position: absolute;
    top: 1em;
    left: 100%;
    margin: -5px 12px 0 12px;
    transition: opacity 0.6s;
    opacity: 0;
  }
}

.SubNavigation__Item--Active {
  a {
    color: $color-ocher;
    font-weight: 600;

    display: inline-block;
    border-bottom: 3px solid #b39048;
    padding-bottom: 2px;
  }

  &:after {
    opacity: 1;
  }
}

@media (max-width: 1200px) {
  .SubNavigation__Item {
    font-size: 1.5vw;
  }
}
