.LinkBox {
  padding: 30px;
  border: 1px solid #cacaca;
  background-color: #fff;
  font-size: 20px;
}

.LinkBox__Header {
  margin-bottom: 20px;
}
.LinkBox__List {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
