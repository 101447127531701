@charset "UTF-8";

@font-face {
  font-family: 'htw-arrows';
  src: url('../../Fonts/htw-arrows.eot');
  src: url('../../Fonts/htw-arrows.eot?#iefix') format('embedded-opentype'),
    url('../../Fonts/htw-arrows.woff') format('woff'),
    url('../../Fonts/htw-arrows.ttf') format('truetype'),
    url('../../Fonts/htw-arrows.svg#htw-arrows') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin iconArrowBase() {
  font-family: 'htw-arrows' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='iconarrow-']:before,
[class*=' iconarrow-']:before,
.iconarrow:before {
  @include iconArrowBase();
}

[data-icon]:before {
  content: attr(data-icon);
}

.icon-slideleft:before {
  content: '\6c';
}
.icon-slideright:before {
  content: '\72';
}

.iconarrow-shuffle:before {
  content: '\36';
}
.iconarrow-reload:before {
  content: '\37';
}

@mixin iconArrowNext {
  content: '\32';
}
.iconarrow-next:before {
  @include iconArrowNext();
}

.iconarrow-forward:before {
  content: '\35';
}
.iconarrow-back:before {
  content: '\31';
}
.mobile-iconarrow-back:before {
  content: '';
  background-image: url(../../../Public/Frontend/Images/htw-svgs/left-arrow.svg);
  display: flex;
  width: 25px;
  height: 15px;
  background-repeat: no-repeat;
}

@mixin iconArrowUp {
  content: '\33';
}
.iconarrow-up:before {
  @include iconArrowUp();
}

@mixin iconArrowDown {
  content: '\34';
}
.iconarrow-down:before {
  @include iconArrowDown();
}

.iconarrow-scroll:before {
  content: '\73';
}
