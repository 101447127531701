.sport-listing {
  .sport-tile {
    height: 242px;
    width: 100%;
    background-color: $color-white;
    margin-top: $tile-margin-top-bottom;
    margin-bottom: $tile-margin-top-bottom;
    border: 1px solid #cacaca;
    padding: 30px;
  }

  .time {
    height: 13px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.44;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 19px;
  }

  .download {
    display: block;
    padding-bottom: 12px;
  }

  .weekday-divider {
    margin-top: 40px;
    h3 {
      @extend h4;
      margin-bottom: 0px;
    }
  }

  .weekday-divider:first-child {
    margin-top: 0px;
  }
}
