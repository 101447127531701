.Plugin--PersonList {
  position: relative;
  width: 100% !important;
}

.Person--Scroller {
  position: relative;

  .Person__Item {
    background-color: #ffffff;
    border: 1px solid #cacaca;
  }

  .Person__Image {
    a {
      display: block;
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .Person__Text {
    padding: 15px 30px 37px 30px;
  }

  .Person__Name {
    font-size: 32px;
    line-height: 38px;
    font-weight: 300;
    margin-bottom: 16px;
    a {
      color: $color-black;
      &:hover {
        display: inline;
        border-bottom: 1px solid black;
        text-decoration: none;
      }
    }
  }

  .Person__Title {
    margin-top: 8px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
  }

  .PersonFunction {
    margin-top: 8px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 11px;
    min-height: 40px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Person__Phone a,
  .Person__Email a {
    line-height: 20px;
    display: inline-block;
    word-break: break-all;
  }
}

// IE11: disable slick-list transform, because it screws up the z-index while scrolling through the list
// https://stackoverflow.com/a/20541859
_:-ms-fullscreen,
:root .Slider--Person .slick-list {
  transform: none;
}

@media screen and (min-width: 769px) {
  .Person--Scroller {
    .Person__Image {
      width: 100%;
      height: auto;
    }
  }

  // Future (not yet scrolled to) items should be already partially visible
  .slick-list {
    overflow: visible;
  }
}

.Slider--Person {
  // Makes sure that all person slider items have the same height
  .slick-slide {
    height: auto;
    > div {
      flex: 1;
      height: 100%;
    }
  }
  .Slider__Slide,
  .Person--Scroller,
  .Person__Item {
    height: 100%;
  }

  .slick-track {
    display: inline-flex;
    width: 100% !important;
    .slick-slide {
      &:first-child {
        margin-left: -30px;
      }
    }
  }
}
