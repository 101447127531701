// this disable vertical scrolling for page content behind the active overlay!
html,
body {
  position: relative;
  height: 100%;
}

body.modal-open {
  overflow-y: hidden !important;
}

html.Overlay--active,
html.Overlay--active body {
  overflow: hidden !important;
}

.Overlay {
  position: static;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;

  background: white;
  border-top: 1px solid #dadadc;

  opacity: 0;
  display: none;

  transition: opacity 0.5s;

  z-index: 99;

  &.Overlay--active {
    display: block;
    opacity: 1;
  }
}

.Overlay__Navigation {
  margin: 40px 40px 0;
  line-height: 1;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
}

.Overlay__Link--Close {
  transition: all 0.2s ease-out;
  transform: scale(1);
  &:hover {
    transform: scale(1.1);
  }
  img {
    width: $mainnav-overlay-close-icon-width;
    height: auto;
    margin-top: -3px;
    margin-left: 5px;
  }
}
.Overlay__Navigation--Left {
  float: left;
  display: table;
  height: 36px;
  margin-top: -9px;
  line-height: 1;

  .Overlay__Link,
  .Overlay__Title {
    display: table-cell;
    vertical-align: middle;
  }
}
.Overlay__Navigation--Right {
  float: right;
}

.Overlay__Link--Next,
.Overlay__Link--Previous {
  transition: all 0.2s ease-out;
  transform: scale(1);
  &:hover {
    transform: scale(1.3);
  }
}

.Overlay__Link {
  display: inline-block;
  cursor: pointer;
}

.Overlay__Title {
  width: 300px;
  text-align: center;
}
