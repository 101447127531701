.frame-type-template_mini_calltoaction {
  h3 {
    @extend h4;
    font-size: 2.5rem;
    margin: 60px 0 25px 0 !important;

    @media (max-width: 768px) {
      font-size: 1.625rem;
    }
  }
}
