.Content--Main > .ContentElement.frame-type-template_entrylinks {
  margin-left: 0;
  margin-right: 0;
}

.InformationLinks {
  padding: 50px 38px;
  border-top: 1px solid #dadcdc;
  border-bottom: 1px solid #dadcdc;

  /** todo: do this better? */
  max-width: 100%;
  overflow: hidden;

  a {
    &[data-link-type] {
      padding-left: 25px !important;
    }
    &[data-link-type='page']:before {
      padding-top: 12px;
    }

    &[data-link-type='url']:before {
      padding-top: 12px;
    }

    &[data-link-type='file']:before {
      padding-top: 12px;
    }
  }
}
.InformationLinks__Wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.InformationLinks__Link,
.InformationLinks__Header {
  vertical-align: middle;
  width: 100%;
  flex: 1 0;

  @include media-breakpoint-down(lg) {
    font-size: 24px;
  }
  @include media-breakpoint-down(md) {
    font-size: 32px;
    padding: 0;
    text-align: center;
  }
}

.InformationLinks__Link {
  font-size: 18px;
  line-height: 2.8;
  font-weight: 500;
  background-color: #7a7760;
  box-shadow: 0 3px 4px rgba($color-black, 0.3);

  @include media-breakpoint-down(md) {
    font-size: 20px;
    line-height: 2.5;
    font-weight: 400;
  }

  a {
    display: block;
    color: #fff;
  }
}

.InformationLinks__Header {
  font-size: 18px;
  line-height: 21px;
  font-weight: 300;
  margin: 0;
  white-space: nowrap;
  padding-left: 0;
  padding: 0 0 22px 0;
}

.InformationLinks__List {
  margin: 0;
  padding: 0;
  list-style-type: none;

  flex: 6 0;
  gap: 38px;

  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.InformationLinks__Link--red a {
  color: $color-red;
}
.InformationLinks__Link--ocher a {
  color: $color-ocher;
}
.InformationLinks__Link--petrol-dark a {
  color: $color-petrol-dark;
}
.InformationLinks__Link--blueish a {
  color: $color-blueish;
}
.InformationLinks__Link--granite-weboptimized a {
  color: $color-granite-weboptimized;
}

.InformationLinks__Link {
  text-align: center;

  a {
    padding-left: 0 !important;

    &[data-link-type] {
      padding-left: 0 !important;
    }

    &:before {
      content: '' !important;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    opacity: 0.8;
  }
}

@include media-breakpoint-down(sm) {
  // Removed some Padding on Mobile so that the Links are still centered
  .InformationLinks {
    padding: 35px 5px;
  }
}
