.MainNavigation__Item--Language,
.MainNavigation__Item--Quick {
  .LanguageNavigation,
  .QuickNavigation {
    position: absolute;
    background: white;
    z-index: 100;
    list-style-type: none;
    padding: 0;
    margin: 0;

    top: calc(100% + 5px);
    right: 999em;
    min-width: 110px;
    background: $color-ocher-light;

    li {
      line-height: 32px;
      &:not(:first-child) {
        border-top: 1px solid $color-white;
      }
      &.current a {
        font-weight: 600;
      }
      a {
        display: block;
        padding: 10px 10px;
      }
    }
  }

  & > a:after {
    @include iconArrowBase();
    content: '\34';
    position: absolute;
    top: 4px;
    right: 0;
  }

  & > a:focus,
  &.active {
    & > a {
      text-decoration: none;
    }
  }

  &.active {
    & > a {
      color: $color-granite-weboptimized;
      &:after {
        content: '\33';
      }
    }
    & > .LanguageNavigation,
    .QuickNavigation {
      right: -10px;
    }
  }
}

.MainNavigation__Item--Quick .QuickNavigation {
  min-width: 264px;
}

.MainNavigation__Item--Language.active .LanguageNavigation {
    min-width: 264px;
    right: -152px;
}

.Navigation__List--OffCanvas {
  .MainNavigation__Item--Quick {
    margin-top: 40px;

    > a {
      font-size: $quicknav-font-size;
      font-weight: 700;

      &:after {
        right: auto;
        top: 12px;
        padding-left: 10px;
      }

      &:hover {
        color: $color-granite-weboptimized;
      }
    }

    .QuickNavigation {
      background-color: $color-white;
      width: 100%;
      padding-bottom: 25px;

      li {
        &:first-child {
          display: block;
        }

        font-size: $quicknav-font-size;
        margin: 0;
        line-height: 24px;

        a {
          &:first-child {
            padding-top: 9px;
          }

          padding: 7px 2px 7px 7px;
        }
      }
    }

    &.active {
      & > .LanguageNavigation,
      .QuickNavigation {
        right: auto;
        left: 0;
      }
    }
  }
}
