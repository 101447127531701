// linked imageBox
.ImageBoxLinked {
  display: block;
  &:hover {
    opacity: 0.8;
  }
}

.ImageBox {
  margin-bottom: 40px;
  padding: 30px;
  border: 1px solid #cacaca;
  background-color: #fff;

  img {
    margin: -30px -30px 30px -30px;
    width: calc(100% + 60px);
    height: auto;

    // for box with an image only (no content)
    &:last-child {
      margin-bottom: -30px;
    }
  }

  figure.portrait img {
    margin-bottom: -46px;
  }

  .header {
    margin: 0 0 5px 0;
    font-size: 32px;
    font-weight: 500;
  }

  p {
    margin: 0 0 5px 0;
    &:last-child {
      margin: 0;
    }
  }

  .socialMediaProfileLinks {
    .socialMediaTitle {
      font-size: 32px;
      font-weight: 500;
      line-height: 38px;
      margin-bottom: 0;
    }

    li {
      padding-top: 16px;

      img {
        margin: 0;
        width: auto;
        height: 23px;
      }
    }
  }

  &.SocialMediaBox {
    padding-top: 44px;
    padding-bottom: 53px;
  }
}
