.GridItem--StudyPath {
  position: relative;

  @include media-breakpoint-down(md) {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.StudyPath--GridItem {
  height: 100%;
  a {
    height: 100%;
    background: orange;
    color: $color-black;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &:hover .StudyPath__MoreLink {
      color: $color-black;
      text-decoration: underline;
    }
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .StudyPath__ImageWrap {
    margin-bottom: 32px;
    position: relative;

    width: 100%;
    padding-top: 56.25%;

    .StudyPath__Image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    iframe,
    img {
      width: 100%;
      height: 100%;
    }
  }

  .StudyPath__Title {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.27;
    margin-bottom: 16px;
    padding-top: 32px;
  }

  .StudyPath__Description {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.44;
    padding-bottom: 2.5rem;
  }

  .StudyPath__MoreLink {
    position: absolute;
    bottom: 3rem;
    color: $color-granite-weboptimized;
    font-size: 20px;
    font-weight: 500;
    // Makes the link clickable for the full width of the box
    width: 95%;
    cursor: pointer;
  }
}
/* Makes full link width clickable on different viewports */
@media (min-width: 1200px) {
  .StudyPath--GridItem {
    .StudyPath__MoreLink {
      width: 80%;
    }
  }
}
@media (max-width: 767px) {
  .StudyPath--GridItem {
    .StudyPath__MoreLink {
      width: 80%;
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .StudyPath--GridItem .StudyPath__Title {
    font-size: 3vw;
  }
}
