@import 'Libs';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';
@import 'Variables';
@import 'BootstrapVariables';

@import 'Fonts/HTW_Icons';
@import 'Fonts/Flaticon';
@import 'Fonts/HtwArrow';
@import 'Fonts/GoogleFont';

@import 'Mixins/Agenda';
@import 'Mixins/ChoicesSelect';
@import 'Mixins/ContentSize';
@import 'Mixins/HeaderNavigationOverlay';
@import 'Mixins/LinkColor';
@import 'Mixins/MiniCallToAction';
@import 'Mixins/Text';
@import 'Mixins/IconLink';

:root {
  --main-header-height: 88px;
  @media screen and (min-width: $lg-screen-width) {
    --main-header-height: 120px;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  overflow-y: auto;
  overflow-x: hidden;
}

html {
  height: 100%;
  scroll-padding-top: 88px;
}

body {
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: visible !important; // This prevents jumping to the top of the page if lightbox opens
  background-color: $body-background-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: $header-height;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

@media (max-width: 768px) {
  .hidden-sm {
    display: none;
  }
}

@media (max-width: 1200px) {
  .hidden-md {
    display: none;
  }
}

// TODO: separate file
.ps--active-x {
  overflow-x: hidden;
}

.ps__scrollbar-x-rail {
  background: #f6f5f5;
  height: 15px;
  position: absolute;
}

.ps__scrollbar-x {
  position: absolute;
  height: 15px;
  background-color: #d86e6c;
  bottom: 0;
}

// TODO: separate file

a {
  color: $color-granite-weboptimized;
}

.Content {
  padding-bottom: 100px;
}

@media (max-width: 768px) {
  .Content--HideOnMobile {
    display: none;
  }
}

@media (min-width: 769px) {
  .Content--SubPage {
    .Content--Main {
      padding-right: 40px;
    }
  }
}

.ContentElement {
  position: relative;
  width: 100%;
}

.ContentElement--Home {
  margin-bottom: 4.7vw;
}

@media (max-width: $sm-screen-width) {
  .ContentElement--Home {
    margin-bottom: 26px;
  }
}

.ContentElement--SubPage {
  &:not(:last-child) {
    margin-bottom: 60px;
  }

  p,
  ul:not(:last-child),
  ol:not(:last-child) {
    margin-bottom: 30px;
  }

  p + ul,
  p + ol {
    position: relative;
    margin-top: -14px;
  }

  &.ContentElement--Title {
    margin-bottom: 30px;
  }
  &.ContentElement--Text {
    margin-bottom: 60px;
  }
}

.ContentWrap {
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.ContentWrap--OneColumn .ContentElement--SubPage,
.ContentWrap--ThreeColumn .ContentElement--SubPage {
  margin-bottom: 38px;
}

.ContentWrap--Home {
  max-width: 100%;
}
@media (max-width: 768px) {
  .Content {
    padding-bottom: 60px;
  }
  .Content--Main > .ContentElement--FullWidth {
    padding-left: 0;
    padding-right: 0;
  }
  .Content--Right > .ContentElement,
  .Content--Main > .ContentElement:not(.ContentElement--FullWidth) {
    padding-left: 22px;
    padding-right: 22px;
  }
}

// XXX> Backport from https://github.com/twbs/bootstrap/pull/26273/commits/90c0cdc0311cc227ca785679c922f6a03dc3acbd
// Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
.modal-dialog-centered::before {
  display: block; // IE10
  height: calc(100vh - (#{$modal-dialog-margin} * 2));
  @include media-breakpoint-up(sm) {
    height: calc(100vh - (#{$modal-dialog-margin-y-sm-up} * 2));
  }
  content: '';
}
// <XXX

@import 'MainNavigation';
@import 'Header';
@import 'HeaderImage';
@import 'Footer';
@import 'Grid';
@import 'LanguageQuickNavigation';
@import 'MediaItem';
@import 'MobileContentAccordion';
@import 'OffCanvasNavigation';
@import 'Overlay';
@import 'Rootline';
@import 'Slider';
@import 'Links';
@import 'Text';
@import 'SpecialLinks';
@import 'SubNavigation';
@import 'Sticker';
@import 'StudyPathSticky';
@import 'Error';
@import 'Chatbot';
@import 'Studyplan';
@import 'Tooltip';

@import 'Form/Input';
@import 'Form/Select';

@import 'ContentElement/Accordeon';
@import 'ContentElement/BigText';
@import 'ContentElement/BigFactBox';
@import 'ContentElement/FactBox';
@import 'ContentElement/GridLaboratory';
@import 'ContentElement/GridStudyPath';
@import 'ContentElement/InformationLinks';
@import 'ContentElement/KeyFactBox';
@import 'ContentElement/Links';
@import 'ContentElement/LinkBox';
@import 'ContentElement/ImageBox';
@import 'ContentElement/MediaGrid';
@import 'ContentElement/MiniCallToAction';
@import 'ContentElement/SliderLogo';
@import 'ContentElement/SliderMediaItem';
@import 'ContentElement/SliderPerson';
@import 'ContentElement/ScrollerGallery';
@import 'ContentElement/ScrollerAgenda';
@import 'ContentElement/ScrollerTestimonial';
@import 'ContentElement/Solr';
@import 'ContentElement/StudyPathPicker';
@import 'ContentElement/Text';

@import 'Templates/SubPage';

@import 'Plugins/PersonDetails';
@import 'Templates/ThreeColumn';
@import 'Plugins/NewsDetails';
@import 'Plugins/Events';
@import 'Plugins/SportXml';
@import 'Plugins/Form';
@import 'Plugins/Sfpgdpr';
@import 'Plugins/Apartment';
@import 'Plugins/Job';
@import 'Templates/OneColumnGrey';

@import 'Templates/Home';
@import 'Templates/Landingpage';

@import 'Accessability';
@import 'Backgrounds';
