.frame-type-template_accordion .ContentSize--Large {
  background-color: $color-ocher-light;

  a {
    font-weight: 800;
    color: $color-black;
  }
}

.frame-type-template_accordion {
  scroll-margin-top: -5.5rem;
  @include media-breakpoint-up(lg) {
    scroll-margin-top: 2rem;
  }
  a[data-link-type='file']:after {
    line-height: 1.81 !important;
  }
}
.ContentElement.ContentElement--SubPage {
  &.frame-type-template_accordion.notLast {
    margin-bottom: 0.625rem;
  }

  &.frame-type-template_accordion {
    margin-bottom: 1.625rem; // 26px #4446.874
    @include media-breakpoint-up(lg) {
      margin-bottom: 3.75rem; // 60px #4446.874
    }
  }
}

.Accordion__Title {
  padding: 20px 0 20px 30px;
  display: flex;

  h3 {
    font-size: 26px;
    font-weight: 300;
    line-height: 1.32;
    margin: 0;
  }

  &:after {
    @include iconArrowBase();
    @include iconArrowDown();

    font-size: 26px;
    padding: 0 18px;
    margin-left: auto;
    align-self: center;
  }

  h4 {
    font-size: 22px;
    margin: 10px 0 0 0;
  }

  cursor: pointer;
}

.Accordion__Title {
  &.PersonDetailPage__CitaviLink {
    &:after {
      @include iconArrowNext();
    }
  }
}

.frame-type-template_accordion.active .Accordion__Title:after {
  @include iconArrowBase();
  @include iconArrowUp();
}

.Accordion__ListItemBodyContent {
  padding: 20px 30px 40px;

  font-size: 20px;
  font-weight: 300;
  line-height: 1.476;

  iframe {
    aspect-ratio: 16 / 9;
    max-width: 100%;
    height: auto;
  }

  h4 {
    font-size: 24px;
  }

  ul li p {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .Accordion__Title {
    h3 {
      font-size: 22px;
      line-height: 1.27;
    }

    h4 {
      font-size: 21px;
    }
  }
}
