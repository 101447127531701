.CustomSelect {
  @include choicesSelect();
}

.CustomSelect select {
  padding: 4px 0 4px 2px;
  background: transparent;
  border: 1px solid #535151;
  width: 100%;
  padding-left: 36px;
  font-size: 20px;
  -webkit-appearance: none;
  appearance: none;
  color: #222;
  line-height: 42px;
}
