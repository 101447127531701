.ApartmentSelect {
  @include choicesSelect();

  select {
    padding: 4px 0 4px 2px;
    background: transparent;
    border: 1px solid #535151;
    width: 100%;
    padding-left: 36px;
    font-size: 20px;
    -webkit-appearance: none;
    appearance: none;
    color: #222;
    line-height: 42px;
  }
}

.Apartment__FilterItem {
  margin-bottom: 20px;

  input.Form__Input--Search {
    padding-left: 36px;

    &:focus + i {
      display: none;
    }
  }

  .CustomSelect {
    .choices__inner {
      border: solid 1px #000000;
      color: #000000;
    }
  }

  .Form__Input {
    border-bottom: solid 1px #000000;
  }

  .icon-search {
    &::before {
      color: #000000;
    }
  }
}

form[id^='apartmentexchange'] {
  @media (min-width: 576px) {
    .form-inline label {
      justify-content: left;
    }
  }
  label {
    margin-bottom: 0.5rem;
  }
}

.Apartment {
  a {
    color: $color-black;
  }

  .Apartment__Email a,
  .Apartment__Phone a {
    color: $color-granite-weboptimized;
  }

  .Apartment__Email a {
    line-height: 1.1;
    display: inline-block;
    word-break: break-all;
  }

  .Apartment__Image {
    width: 100%;
    height: auto;
  }

  .Apartment__Text {
    padding: 30px;
  }

  .Apartment__Name {
    font-size: 30px;
    font-weight: 200;

    a {
      &:hover {
        display: inline;
        border-bottom: 1px solid $color-black;
        text-decoration: none;
      }
    }
  }

  .Apartment__Title {
    margin-bottom: 12px;
  }
}

.Apartments {
  margin-top: 60px;

  & > div {
    display: flex;
    margin-bottom: 60px;

    & .ApartmentItem {
      width: 100%;
      height: auto;
      border: 1px solid #cacaca;
      background: white;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}
