header.Header {
  position: absolute;
  z-index: $zindex-header;
  top: 0;
  width: 100%;
  max-width: none;
  height: $header-height;
  padding: 12px 37px 0 26px;
  background-color: $body-background-color;

  &:after,
  &:before {
    content: '';
    clear: both;
    display: table;
  }

  @media screen and (min-width: $lgplus-screen-width) {
    position: fixed;
    transition: top 0.3s ease-in-out;
    &.header-hidden {
      top: -$header-height;
    }
  }
}

.Header__Logo {
  width: 222px;

  @media screen and (min-width: $xs-screen-width + 1) {
    width: 314px;
    height: 66px;
  }

  float: left;

  margin-top: 7px;
  margin-left: 13px;

  @media screen and (min-width: $lg-screen-width) {
    margin-top: 25px;
  }

  .Logo,
  .Logo > a,
  .Logo__Image {
    max-width: 100%;
    height: auto;
  }
}
.Overlay--active .Header__Logo {
  @media screen and (max-width: $lg-screen-width) {
    display: none;
  }
}

.Header__Navigation {
  float: right;
}

.Header__Search {
}

.Header__Language {
}
.Header__OffCanvasToggle {
  position: absolute;
  z-index: 999;
  top: -1000px;
  right: 17px;
  transition: top 0.3s ease-in-out;
  &.header-hidden {
    top: -$header-height;
  }
}

.SearchSubmit--Header {
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s ease-out;
  transform: scale(1);
  &:hover {
    transform: scale(1.1);
  }
}

.SearchForm--Header {
  position: absolute;
  z-index: 101;
  right: 0;

  margin-top: -20px;
  padding-bottom: 10px;

  width: 0;
  overflow: hidden;

  transition: width 0.5s;
  background: white;

  input {
    width: calc(100% - 80px);
  }

  &.active {
    // width of search not obstructing the logo
    // Either respecting the left edge of the content, or not obstructing the logo if the view is smaller
    width: min(calc(50% + 800px - 365px), calc(100vw - 380px));
  }

  button {
    position: absolute;
    right: 0;
    margin-right: 3px;
    top: 12px;
    background: none;
    border: none;

    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  header.Header {
    width: calc(100% - 16px);
    margin-left: 8px;
    padding-left: 0;
  }
}

@media screen and (max-width: $lgplus-screen-width) {
  .Header__Navigation {
    display: none;
  }
  .Header__Search {
    display: none;
  }
  .Header__Language {
    display: none;
  }
  .Header__Logo {
    position: absolute;
  }
  .Header__OffCanvasToggle {
    display: block;
    top: 14px;

    &.sticky {
      position: fixed;
    }
  }
}
