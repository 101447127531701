.Footer {
  position: relative;
  margin-top: -50px;
  padding-top: 50px;

  &__TriangleWrapper {
    position: absolute;
    top: calc(-5vw + 51px);
    left: 0;
    width: 100%;
    overflow: hidden;
  }

  &__Triangle {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-bottom: 5vw solid $color-granite-weboptimized;
    border-left: 100vw solid transparent;
  }
}

.FooterContent {
  background: $color-granite-weboptimized;
  color: white;
  position: relative;

  a {
    color: white;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }

  .row {
    margin: 0 -15px; //TODO: ugh I need to fix this elsewhere
  }

  .FooterContent__Wrapper {
    margin: 0 auto;

    padding: 50px 0 0;
  }

  .FooterContent__Title {
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 25px;
  }

  .FooterContent__Bodytext {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.44;
    columns: 1;
    width: 100%;

    @include media-breakpoint-up(lg) {
      columns: 2;
    }

    p {
      margin-bottom: 2em;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .FooterContent__LinkList {
    margin: 0 0 45px 0;
    padding: 0;
    list-style-type: none;
  }

  .FooterContent__LinkItem {
    font-size: 16px;
    line-height: 1.81;
    position: relative;
    padding-left: 46px;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }

    a:hover {
      text-decoration: underline;
    }

    i {
      position: absolute;
      font-size: 24px;
      left: 0;
      top: -2px;
      width: 30px;
      text-align: center;
    }
  }

  /** TODO!! */
  select {
    background: transparent;
    border: 1px solid white;
    color: white;
    padding: 15px 30px;
  }

  .FooterContent__SocialMediaList {
    list-style-type: none;
    margin: 0 0 50px 0;
    padding: 0;
  }
}

.FooterContent__SocialMediaList {
  list-style-type: none;
  margin: 32px 0 50px 0;
  padding: 0;
}
.FooterContent__SocialMediaItem {
  float: left;
  font-size: 32px;
  margin-right: 48px;

  a {
    i {
      display: inline-block;
      transition: all 0.2s ease-out;
      transform: scale(1);
      color: $color-black;
    }
    &:hover i {
      transform: scale(0.8);
    }
  }
}

.FooterContent,
.FooterEnd {
  width: 100%;
  overflow: hidden;
  padding: 0 40px;
}

.FooterBorder {
  display: none;
}

.FooterEnd {
  min-height: 100px;
  margin: 0;

  .row {
    margin: 0 -15px;
  }
}

.FooterLogos {
  justify-content: flex-end;
  margin: 0px !important;
}

.QualityLabels > ul,
.FooterLogos {
  list-style-type: none;
  height: 100px;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: right;

  li {
    height: 100px;
    line-height: 100px;

    img {
      display: inline-block;
    }
  }

  .QualityLogo {
    height: 100px;
    line-height: 100px;
    display: inline-block;

    &:first-child {
      float: left;
    }
  }
}
.QualityLabels {
  padding: 0 40px;
  border-bottom: 1px solid #dadadc;

  ul {
    li {
      max-width: 240px;
      &:not(:first-child) {
        margin-left: 80px;
      }
      a {
        display: inline-block;
        &:hover {
          opacity: 0.8;
        }
      }
      img {
        width: auto;
        max-width: 100%;
        height: 80px;
      }
    }
  }
}
.FooterLogos {
  li {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.FooterNavigation {
  height: 100px;

  list-style-type: none;
  margin: 0;
  padding: 0;

  float: right;

  li {
    float: left;

    margin: 0;
    &:not(:first-child) {
      margin-left: 25px;
    }

    font-size: 14px;

    a {
      color: $color-gray-dark;
      line-height: 100px;
    }
  }
}

@media (max-width: 768px) {
  .Footer {
    .FooterContent__Wrapper {
      padding-left: 22px;
      padding-right: 22px;
    }
    .FooterContent__LinkListWrapper {
      margin-top: 40px;
    }
    .FooterNavigation {
      height: auto;
      float: none;
      padding: 10px 0;
      li {
        width: 33.333%;
        margin-left: 0;
        text-align: center;

        &:nth-child(4n) {
          clear: left;
        }
        a {
          line-height: 50px;
        }
      }
    }

    .FooterContent {
      padding: 0;
    }

    .FooterEnd {
      padding: 0 10px;
      .row {
        margin: 0 -15px;
      }
    }
  }
}
@media (min-width: 769px) {
  .FooterNavigation {
    display: flex;
    margin-right: 40px;
  }
}
@media (min-width: 1200px) {
  .FooterContent__TextContentWrapper {
    max-width: 1200px;
  }
  .FooterContent__LinkListWrapper {
    float: right;
  }
}

@media (max-width: 1200px) {
  .QualityLabels > ul {
    .QualityLogo {
      display: none;

      &:first-child {
        float: left;
        display: block;
      }
    }
  }
}

.BackToTop {
  position: fixed;
  bottom: 30px;
  right: 9999em;
  background: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 10;

  @include LinkColor(black, $color-ocher);

  i {
    font-size: 24px;
    text-align: center;
    width: 100%;
    display: block;
    line-height: 40px;
    position: relative;
    top: 3px;
  }

  &.init:not(.visible) {
    animation: hideBackToTop 0.5s;
  }

  &.visible {
    animation: showBackToTop 0.5s;
    right: 16px;
  }
}

@keyframes showBackToTop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes hideBackToTop {
  0% {
    opacity: 1;
    right: 16px;
  }
  99% {
    right: 16px;
  }
  100% {
    opacity: 0;
    right: 9999em;
  }
}

@mixin mobileSpacingFooter {
  margin: 0 $header-image-left-right-spacing-mobile;
}

@media screen and (max-width: $xs-screen-width) {
  .Footer {
    margin-top: -25px;
    color: $white;
    background: #7a7760;

    li a {
      color: inherit;
    }

    .Footer__TriangleWrapper {
      top: calc(-5vw + 1px);
    }

    .FooterContent {
      .FooterContent__Wrapper {
        display: none;
      }
    }

    .QualityLabels {
      @include mobileSpacingFooter;

      border-bottom: none;
      padding: 0;

      .QualityLogo {
        &:first-child {
          img {
            filter: invert(100%) sepia(0%) saturate(7498%) hue-rotate(298deg)
              brightness(105%) contrast(103%);
          }
        }
      }
    }

    .FooterBorder {
      display: block;
      border-top: 1px solid $white;
    }

    .FooterEnd {
      @include mobileSpacingFooter;

      width: auto;
      padding: 0;

      .FooterContent__SocialMediaList {
        display: flex;
        max-width: 400px;
        margin-bottom: 32px;

        .FooterContent__SocialMediaItem {
          padding-right: 10px;
          margin-right: auto;

          &.flickr,
          &.xing {
            display: none;
          }
        }
      }
    }

    .FooterNavigation {
      margin-right: 0;

      li {
        text-align: left;

        &.Sitemap,
        &.Intranet,
        &.Moodle {
          display: none;
        }
      }
    }

    .FooterContent__SocialMediaItem a i {
      color: $white;
    }
  }
}
