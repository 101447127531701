@mixin choicesSelect() {
  .choices__inner {
    width: 100%;
    min-height: 52px;
    border: solid 1px $color-gray-dark;
    background: none;

    padding: 10px 0 0 36px;
  }

  .choices__list--single {
    padding: 0;
  }

  .choices__input--cloned::placeholder {
    color: $color-black;
  }

  .choices__placeholder {
    opacity: 1;
  }

  .choices[data-type*='select-multiple'] {
    .choices__button {
      border-left-color: #fff5;
    }
    .choices__input {
      // choices.js normally tries to let the input fit right to the selected options
      // the calculation by choices doesnt work with our different font-sizes etc.
      width: auto !important;
    }
  }
  .choices[data-type*='select-one'] {
    .choices__inner {
      padding-top: 9px;
    }
    .choices__button {
      display: none;
    }
  }

  .choices__list--dropdown {
    z-index: $zindex-choices-dropdown;
    border-color: $color-gray-dark;
  }

  .choices[data-type*='select-one']:after {
    display: none;
  }
  .choices[data-type*='select-one']:before {
    @include iconArrowBase();
    @include iconArrowDown();

    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -8px;
  }

  .choices__list--dropdown {
    .choices__item--selectable.is-highlighted {
      background: $color-ocher-light;
    }
    .choices__item {
      padding-left: 36px;
      padding-right: 18px;
    }
  }

  .choices__list--multiple .choices__item {
    /* ... for too long text (not working yet)
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    */
    hyphens: auto;
    background-color: $color-granite-weboptimized;
    border-width: 0;
    border-radius: 6px;
  }
}
