@import 'Variables';
@import 'BootstrapVariables';

@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

@import '~lightcase/src/css/lightcase';

@import '~jquery-ui/themes/base/core';
@import '~jquery-ui/themes/base/datepicker';
@import '~jquery-ui/themes/base/theme';

$choices-selector: 'choices' !default;
$choices-font-size-lg: 16px !default;
$choices-font-size-md: 20px !default;
$choices-font-size-sm: 12px !default;
$choices-guttering: 24px !default;
$choices-border-radius: 0 !default;
$choices-border-radius-item: 20px !default;
$choices-bg-color: #f7f8fb !default;
$choices-bg-color-disabled: #f7f8fb !default;
$choices-bg-color-dropdown: #f7f8fb !default;
$choices-text-color: #333333 !default;
$choices-keyline-color: #dddddd !default;
$choices-primary-color: #00bcd4 !default;
$choices-disabled-color: #eaeaea !default;
$choices-highlight-color: $choices-primary-color !default;
$choices-button-icon-path: '../../icons' !default;
$choices-button-dimension: 8px !default;
$choices-button-offset: 8px !default;
@import '~choices.js/assets/styles/scss/choices';

@import '~slick-carousel/slick/slick';
